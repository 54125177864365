import { useCallback, useMemo } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

type PaginationControlProps = {
  totalRecords: number;
  recordsPerPage: number;
  page?: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  loading: boolean;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
};

const PaginationControl = ({
  totalRecords,
  recordsPerPage,
  page,
  onPreviousPage,
  onNextPage,
  hasPreviousPage,
  hasNextPage,
  justifyContent = 'flex-end',
  loading,
}: PaginationControlProps) => {
  const numPages = useMemo(
    () =>
      Math.floor(totalRecords / recordsPerPage) +
      (totalRecords % recordsPerPage > 0 ? 1 : 0),
    [totalRecords, recordsPerPage],
  );

  const pageInfoLabel = useMemo<string>(() => {
    if (!numPages || !page) {
      return '';
    }
    const numOfPriorRecords = (page - 1) * recordsPerPage;
    const recordsRemaining = totalRecords - numOfPriorRecords;
    const min = numOfPriorRecords + 1;
    const max =
      numOfPriorRecords +
      (recordsRemaining <= recordsPerPage ? recordsRemaining : recordsPerPage);

    return `${min}-${max} out of ${totalRecords}`;
  }, [totalRecords, recordsPerPage, numPages, page]);

  const handlePreviousClicked = useCallback(() => {
    if (hasPreviousPage) {
      onPreviousPage();
    }
  }, [hasPreviousPage, onPreviousPage]);

  const handleNextClicked = useCallback(() => {
    if (hasNextPage) {
      onNextPage();
    }
  }, [hasNextPage, onNextPage]);

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent={justifyContent}
      p={2}
    >
      <Typography variant='body2' fontWeight='500'>
        {pageInfoLabel}
      </Typography>
      <Box display='flex' flexDirection='row' alignItems='center' ml={1}>
        <IconButton
          aria-label='previous'
          disabled={loading || !hasPreviousPage}
          onClick={handlePreviousClicked}
        >
          <NavigateBeforeIcon
            sx={{
              color:
                loading || !hasPreviousPage ? 'neural.light' : 'neural.dark',
            }}
          />
        </IconButton>
        <IconButton
          aria-label='next'
          disabled={loading || !hasNextPage}
          onClick={handleNextClicked}
        >
          <NavigateNextIcon
            sx={{
              color: loading || !hasNextPage ? 'neural.light' : 'neural.dark',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PaginationControl;
