import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import isEqual from 'lodash/isEqual';
import Dialog from '../components/shared/Dialog/index';
import {
  DIALOG_IDS,
  DIALOG_REGISTRY,
  DialogComponentPropsMapping,
} from '../components/registeredDialogs/dialogRegistry';

export type AsyncResult = boolean | string[];

// for secondary button
export type BaseDialogActionType = {
  text: string;
  disabled?: boolean;
  action?: () => void;
  buttonColor?: 'primary' | 'secondary' | 'error';
};

// for primary button, it can have either action or asyncAction
export type DialogActionType = BaseDialogActionType &
  (
    | {
        action: () => void;
        asyncAction?: never;
      }
    | {
        asyncAction: () => Promise<AsyncResult>;
        action?: never;
        successMessage?: string;
        errorMessage?: string;
      }
  );

export interface DialogConfig<ID extends DIALOG_IDS> {
  id: ID;
  title: string;
  contentProps: Omit<DialogComponentPropsMapping[ID], 'hideDialog'>;
  primaryAction?: DialogActionType;
  secondaryAction?: BaseDialogActionType;
  instanceId: symbol;
}

type Action =
  | { type: 'SHOW_DIALOG'; dialog: DialogConfig<DIALOG_IDS> }
  | {
      type: 'UPDATE_DIALOG_CONTENT';
      id: DIALOG_IDS;
      contentProps: DialogConfig<DIALOG_IDS>['contentProps'];
    }
  | { type: 'HIDE_DIALOG'; id: DIALOG_IDS };

interface DialogContextStore {
  dialogs: DialogConfig<DIALOG_IDS>[];
  dispatch: Dispatch<Action>;
}

const initialState: DialogContextStore = {
  dialogs: [],
  dispatch: () => {
    /* intentionally empty */
  },
};

const DialogContext = createContext<DialogContextStore>(initialState);

const dialogReducer = (dialogs: DialogConfig<DIALOG_IDS>[], action: Action) => {
  switch (action.type) {
    case 'SHOW_DIALOG': {
      const { dialog } = action;
      if (!DIALOG_REGISTRY[dialog.id]) {
        throw new Error(
          `Dialog id ${action.dialog.id} is not found in the registry.`,
        );
      }

      // check if the dialog is already open
      // if found replace it with the new one
      // and move it to the end of the array
      const existingDialogIndex = dialogs.findIndex(
        (dialog) => dialog.id === action.dialog.id,
      );
      if (existingDialogIndex !== -1) {
        dialogs.splice(existingDialogIndex, 1);
      }

      return [...dialogs, action.dialog];
    }
    case 'UPDATE_DIALOG_CONTENT': {
      const { id, contentProps } = action;
      const existingDialogIndex = dialogs.findIndex(
        (dialog) => dialog.id === id,
      );
      if (existingDialogIndex !== -1) {
        dialogs[existingDialogIndex].contentProps = contentProps;
      }
      return [...dialogs];
    }
    case 'HIDE_DIALOG':
      return dialogs.filter((dialog) => dialog.id !== action.id);
    default:
      return dialogs;
  }
};

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogs, dispatch] = useReducer(dialogReducer, initialState.dialogs);
  const value = useMemo(() => ({ dialogs, dispatch }), [dialogs, dispatch]);

  useEffect(() => {
    // close all dialogs on window history change (back button)
    const handleRouteChange = () => {
      if (dialogs?.length) {
        dialogs.forEach((dialog) => {
          dispatch({ type: 'HIDE_DIALOG', id: dialog.id });
        });
      }
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [dialogs]);

  const hideDialog = useCallback(
    (id: DIALOG_IDS) => {
      dispatch({ type: 'HIDE_DIALOG', id });
    },
    [dispatch],
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      <DialogPlaceholder hideDialog={hideDialog} />
    </DialogContext.Provider>
  );
};

const DialogPlaceholder = ({
  hideDialog,
}: {
  hideDialog: (id: DIALOG_IDS) => void;
}) => {
  const { dialogs } = useContext(DialogContext);

  if (!dialogs?.length) return <></>;

  return (
    <>
      {dialogs.map((dialog) => {
        if (dialog) {
          return (
            <Dialog
              id={dialog.id}
              open={true}
              key={dialog.id}
              title={dialog.title}
              contentProps={{
                ...dialog.contentProps,
                hideDialog: () => hideDialog(dialog.id),
              }}
              primaryAction={dialog.primaryAction}
              secondaryAction={dialog.secondaryAction}
            />
          );
        }
      })}
    </>
  );
};

interface UseDialogProps<ID extends DIALOG_IDS> {
  id: ID;
  title: string;
  contentProps: Omit<DialogComponentPropsMapping[ID], 'hideDialog'>;
  primaryAction?: DialogActionType;
  secondaryAction?: BaseDialogActionType;
}

type ShowDialogArgs = {
  title?: string;
  primaryAction?: DialogActionType;
  secondaryAction?: BaseDialogActionType;
};

export const useDialog = <ID extends DIALOG_IDS>({
  id,
  title,
  contentProps,
  primaryAction,
  secondaryAction,
}: UseDialogProps<ID>) => {
  const { dispatch, dialogs } = useContext(DialogContext);

  // creates a unique id for each instance of the dialog
  const instanceId = useRef(Symbol());

  const showDialog = useCallback(
    (args?: ShowDialogArgs) => {
      const {
        title: titleOverride,
        primaryAction: primaryActionOverride,
        secondaryAction: secondaryActionOverride,
      } = args ?? {};

      const dialog: DialogConfig<ID> = {
        id,
        title: titleOverride ?? title,
        contentProps: contentProps,
        primaryAction: primaryActionOverride ?? primaryAction,
        secondaryAction: secondaryActionOverride ?? secondaryAction,
        instanceId: instanceId.current,
      };
      dispatch({ type: 'SHOW_DIALOG', dialog });
    },
    [contentProps, dispatch, id, primaryAction, secondaryAction, title],
  );

  // update the dialog if contentProps changes
  useEffect(() => {
    if (!dialogs.length) return;

    const matchingDialog = dialogs.find(
      (dialog) => dialog.instanceId === instanceId.current,
    );

    if (matchingDialog && !isEqual(contentProps, matchingDialog.contentProps)) {
      dispatch({
        type: 'UPDATE_DIALOG_CONTENT',
        id: matchingDialog.id,
        contentProps,
      });
    }
  }, [contentProps, dialogs, dispatch, id]);

  const hideDialog = useCallback(() => {
    dispatch({ type: 'HIDE_DIALOG', id });
  }, [dispatch, id]);

  return {
    showDialog,
    hideDialog,
  };
};
