import { createContext, useCallback, useContext } from 'react';
import {
  useWorkspaceAssociatedExploresLazyQuery,
  usePickerColumnDataLazyQuery,
  usePickerSchemaLazyQuery,
  WorkspaceAssociatedExploresQuery,
} from '../generated/types';

// store
import { useAppSelector, useAppDispatch } from './store';
import {
  DataExploreConfig,
  setColumnarSlices,
  toExploreExtract,
  toColumnarSlices,
  updateExploreExtract,
  makeExtractId,
} from '../store/slices/exploreExtracts';
import {
  LoadState,
  updateExploreForWorkspace,
  updateWorkspaceExploresLoadState,
} from '../store/slices/loadedWorkspaceExplores';

// hook
import { useSessionInfo } from './session';

export interface PickerDataService {
  isDataLoaded: (workspaceId: string) => boolean;
  loadDataFor: (workspaceId: string, forced?: boolean) => void;
}

export const PickerDataContext = createContext({
  isDataLoaded: () => false,
  loadDataFor: () => {
    /**  intentionally empty */
  },
} as PickerDataService);

type PickerSchemaFetcher = (dataExploreCfg: DataExploreConfig) => Promise<void>;

type PickerDataFetcher = (
  dataExploreCfg: DataExploreConfig,
  workflowId: string,
) => void;

const exploreDataUpdater =
  (
    workspaceId: string,
    storeDispatch: ReturnType<typeof useAppDispatch>,
    pickerSchemaFetcher: PickerSchemaFetcher,
    isExtractAvailable: (extractId: string) => boolean,
    pickerDataFetcher?: PickerDataFetcher,
  ) =>
  (data: WorkspaceAssociatedExploresQuery) => {
    if (!workspaceId) return;
    if (
      data &&
      data?.node?.__typename === 'Workspace' &&
      data.node.lookerExplores
    ) {
      data.node.lookerExplores.forEach((lookerExplore) => {
        const dataExploreCfg: DataExploreConfig = {
          providerId: lookerExplore.explore.externalProviderId,
          modelName: lookerExplore.explore.dataModelName,
          modelExploreName: lookerExplore.explore.exploreName,
          customerFilterName: lookerExplore.explore.customerField ?? undefined,
          customerFilterValues: lookerExplore.customerFilterValues ?? [],
        };

        const extractId = makeExtractId(dataExploreCfg);
        const alreadyAvailable = isExtractAvailable(extractId);
        const exploreInfo = {
          wsExploreId: lookerExplore.id,
          envExploreId: lookerExplore.explore.id,
          exploreConfig: { ...dataExploreCfg },
          extractId,
          loadState: alreadyAvailable ? LoadState.LOADED : LoadState.LOADING,
        };

        // skip the actual loading if an explore extract is available for use/re-use
        // the record keeping above is still necessary
        if (alreadyAvailable) {
          storeDispatch(
            updateExploreForWorkspace({
              workspaceId,
              exploreInfo,
            }),
          );
          return;
        }

        pickerSchemaFetcher(dataExploreCfg).then(() => {
          // mark the explore schema as loaded
          storeDispatch(
            updateExploreForWorkspace({
              workspaceId,
              exploreInfo,
            }),
          );

          // initiate the asynchronous fetch for data
          pickerDataFetcher && pickerDataFetcher(dataExploreCfg, workspaceId);
        });
      });
    }
  };

export const usePickerDataContext = (): PickerDataService => {
  const availableWorkspaceExplores = useAppSelector(
    (state) => state.workspace.value.workspaceExplores,
  );
  const loadedExplores = useAppSelector((state) => state.loadedExplores.value);
  const exploreExtracts = useAppSelector(
    (state) => state.exploreExtracts.value,
  );

  const { isSignedIn, isWorkspaceViewer } = useSessionInfo();

  const storeDispatch = useAppDispatch();

  const [fetchAssociatedExplores] = useWorkspaceAssociatedExploresLazyQuery();

  const [fetchPickerSchema] = usePickerSchemaLazyQuery();
  const [fetchPickerColumnData] = usePickerColumnDataLazyQuery();

  const pickerSchemaFetcher: PickerSchemaFetcher = useCallback(
    async (dataExploreCfg: DataExploreConfig) => {
      try {
        const result = await fetchPickerSchema({
          variables: {
            ...dataExploreCfg,
          },
        });

        const pickerSchema = result?.data?.pickerSchema;
        if (pickerSchema) {
          const extractId = makeExtractId(dataExploreCfg);
          storeDispatch(
            updateExploreExtract(toExploreExtract(extractId, pickerSchema)),
          );
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(
            `Error loading picker schema for explore - ${error.message}`,
          );
        }
      }
    },
    [fetchPickerSchema, storeDispatch],
  );

  const isExtractAvailable = useCallback(
    (extractId: string): boolean => {
      return !!exploreExtracts[extractId];
    },
    [exploreExtracts],
  );

  const pickerDataFetcher: PickerDataFetcher = useCallback(
    (dataExploreCfg: DataExploreConfig, workspaceId: string) => {
      const { customerFilterName, customerFilterValues, ...baseVariables } =
        dataExploreCfg;

      const customerFilter =
        !!customerFilterName && !!customerFilterValues
          ? { customerFilterName, customerFilterValues }
          : undefined;

      fetchPickerColumnData({
        variables: customerFilter
          ? { ...baseVariables, customerFilter }
          : { ...baseVariables },
      })
        .then((res) => {
          if (res?.data) {
            const extractId = makeExtractId(dataExploreCfg);
            const columnarSlices = toColumnarSlices(res.data);
            storeDispatch(
              setColumnarSlices({
                id: extractId,
                sliceInfo: columnarSlices,
              }),
            );
            // mark explore data as 'LOADED'
            storeDispatch(
              updateWorkspaceExploresLoadState({
                workspaceId,
                extractId,
                newState: LoadState.LOADED,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          if (error instanceof Error) {
            console.error(
              `Error loading picker data for explore - ${error.message}`,
            );
          }
        });
    },
    [fetchPickerColumnData, storeDispatch],
  );

  const loadExplores = useCallback(
    (workspaceId: string) => {
      fetchAssociatedExplores({
        variables: { workspaceId },
      })
        .then(({ data }) => {
          if (data) {
            exploreDataUpdater(
              workspaceId,
              storeDispatch,
              pickerSchemaFetcher,
              isExtractAvailable,
              !isWorkspaceViewer ? pickerDataFetcher : undefined,
            )(data);
          }
        })
        .catch((error: unknown) => {
          if (error instanceof Error) {
            console.error(
              `Error loading explores for workspace - ${error.message}`,
            );
          }
        });
    },
    [
      fetchAssociatedExplores,
      pickerSchemaFetcher,
      pickerDataFetcher,
      isExtractAvailable,
      isWorkspaceViewer,
      storeDispatch,
    ],
  );

  const isDataLoaded = useCallback(
    (workspaceId?: string) => {
      if (!workspaceId) return false;

      const loadedForWorkspace = loadedExplores[workspaceId];
      if (!loadedForWorkspace) return false;

      const loadedExploreIds = Object.keys(loadedForWorkspace.byEnvExploreId);
      if (!loadedExploreIds.length) return false;

      // Checks if all workspace explores are loaded
      const availableExploreIds =
        availableWorkspaceExplores?.map((explore) => explore.exploreId) ?? [];
      const availableExploresNotLoaded = availableExploreIds.filter(
        (x) => !loadedExploreIds.includes(x),
      );
      if (availableExploresNotLoaded.length) return false;

      // for viewer, data is considered as loaded so long as schemas for the explores are loaded
      // as viewer doesn't care about the actual picker data
      if (isWorkspaceViewer) return true;

      for (const id of loadedExploreIds) {
        if (
          loadedForWorkspace.byEnvExploreId[id].loadState !== LoadState.LOADED
        )
          return false;
      }
      return true;
    },
    [loadedExplores, isWorkspaceViewer, availableWorkspaceExplores],
  );

  const loadDataFor = useCallback(
    (workspaceId: string, forced = false) => {
      if (isSignedIn && (!isDataLoaded(workspaceId) || forced)) {
        loadExplores(workspaceId);
      }
    },
    [isSignedIn, loadExplores, isDataLoaded],
  );

  return {
    isDataLoaded,
    loadDataFor,
  };
};

export const usePickerData = () => {
  const { loadDataFor } = useContext(PickerDataContext);
  return [loadDataFor] as const;
};
