import { Chip, Stack } from '@mui/material';
import { Emoji } from 'emoji-picker-react';
import { BadgeSetting } from '@madeinventive/core-types';

interface BadgeProps extends BadgeSetting {
  text: string;
}

const Badge = ({ text, color, emoji }: BadgeProps) => {
  return (
    <Chip
      size='small'
      label={text}
      icon={
        emoji ? (
          <Stack>
            <Emoji size={18} unified={emoji} />
          </Stack>
        ) : undefined
      }
      sx={{
        backgroundColor: color,
      }}
    />
  );
};

export default Badge;
