import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Workspace,
  Feature,
  WorkspaceMember,
  Integration,
  WorkspaceLookerDataModelExplore,
  VizGenerationMode,
} from '../../generated/types';

export const GPT4_TURBO = 'gpt-4-turbo-2024-04-09';
export const GPT4_OMNI = 'gpt-4o-2024-05-13';
export interface ChatConfig {
  temperature: number;
  model: string;
  enableQuickFollow: boolean;
  enableAnswerabilityCheck: boolean;
  enableCommonQuestionHints: boolean;
  enableDataScientist: boolean;
  enableFieldReduction: boolean;
  enableDataTransformation: boolean;
  enableEmbeddings: boolean;
  vizGenerationMode: VizGenerationMode;
}

interface WorkspaceState {
  workspace?: Workspace;
  features: Feature[];
  members: WorkspaceMember[];
  integrations: Integration[];
  workspaceExplores: WorkspaceLookerDataModelExplore[] | null;

  chatConfig: ChatConfig;
  expandedThoughts: string[];
  dataSourceOptions?: {
    value: string;
    label: string;
    filterValues: string[];
  }[];
}

const initialState: WorkspaceState = {
  workspace: undefined,
  features: [],
  members: [],
  integrations: [],
  workspaceExplores: null,

  chatConfig: {
    temperature: 0.0,
    model: GPT4_OMNI,

    enableQuickFollow: false,
    enableAnswerabilityCheck: false,
    enableCommonQuestionHints: true,
    enableDataScientist: true,
    enableDataTransformation: false,
    enableEmbeddings: true,
    enableFieldReduction: true,
    vizGenerationMode: VizGenerationMode.PLANNER,
  },
  expandedThoughts: [],
  dataSourceOptions: undefined,
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    value: initialState,
  },
  reducers: {
    setWorkspace: (state, action: PayloadAction<Workspace | undefined>) => {
      state.value = {
        ...state.value,
        workspace: action.payload,
      };
    },
    setFeatures: (state, action: PayloadAction<Feature[]>) => {
      state.value = {
        ...state.value,
        features: action.payload,
      };
    },
    deleteFeature: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        features: state.value.features.filter(
          (feature) => feature.id !== action.payload,
        ),
      };
    },
    updateFeature: (state, action: PayloadAction<Feature>) => {
      state.value = {
        ...state.value,
        features: state.value.features.map((feature) =>
          feature.id === action.payload.id ? action.payload : feature,
        ),
      };
    },
    updateFeatureName: (
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) => {
      state.value = {
        ...state.value,
        features: state.value.features.map((feature) =>
          feature.id === action.payload.id
            ? { ...feature, name: action.payload.name }
            : feature,
        ),
      };
    },
    addFeature: (state, action: PayloadAction<Feature>) => {
      state.value = {
        ...state.value,
        features: [...state.value.features, action.payload].sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    },
    setMembers: (state, action: PayloadAction<WorkspaceMember[]>) => {
      state.value = {
        ...state.value,
        members: action.payload,
      };
    },
    updateMember: (state, action: PayloadAction<WorkspaceMember>) => {
      state.value = {
        ...state.value,
        members: state.value.members.map((member) =>
          member.id === action.payload.id ? action.payload : member,
        ),
      };
    },
    addMember: (state, action: PayloadAction<WorkspaceMember>) => {
      state.value = {
        ...state.value,
        members: [...state.value.members, action.payload],
      };
    },
    deleteMember: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        members: state.value.members.filter(
          (member) => member.id !== action.payload,
        ),
      };
    },
    deactivateMember: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        members: state.value.members.map((member) =>
          member.id === action.payload
            ? { ...member, status: 'DEACTIVATED' }
            : member,
        ),
      };
    },
    setIntegrations: (state, action: PayloadAction<Integration[]>) => {
      state.value = {
        ...state.value,
        integrations: action.payload,
      };
    },
    deleteIntegration: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        integrations: state.value.integrations.filter(
          (integration) => integration.id !== action.payload,
        ),
      };
    },
    setWorkspaceExplores: (
      state,
      action: PayloadAction<WorkspaceLookerDataModelExplore[]>,
    ) => {
      state.value = {
        ...state.value,
        workspaceExplores: action.payload,
      };
    },
    addWorkspaceExplore: (
      state,
      action: PayloadAction<WorkspaceLookerDataModelExplore>,
    ) => {
      state.value = {
        ...state.value,
        workspaceExplores: [
          ...(state.value.workspaceExplores ?? []),
          action.payload,
        ],
      };
    },
    deleteWorkspaceExplore: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        workspaceExplores:
          state.value.workspaceExplores?.filter(
            (explore) => explore.id !== action.payload,
          ) ?? null,
      };
    },
    clearWorkspace: () => ({
      value: initialState,
    }),
    updateChatConfig: (state, action: PayloadAction<ChatConfig>) => {
      state.value = {
        ...state.value,
        chatConfig: action.payload,
      };
    },
    addExpandedThought: (state, action: PayloadAction<string>) => {
      // check if the action.payload is already in the expandedThoughts array
      if (state.value.expandedThoughts.includes(action.payload)) {
        return;
      }

      state.value = {
        ...state.value,
        expandedThoughts: [...state.value.expandedThoughts, action.payload],
      };
    },
    removeExpandedThought: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        expandedThoughts: state.value.expandedThoughts.filter(
          (id) => id !== action.payload,
        ),
      };
    },
    setDataSourceOptions: (state, action) => {
      state.value.dataSourceOptions = action.payload;
    },
  },
});

export const {
  setWorkspace,
  setFeatures,
  deleteFeature,
  updateFeature,
  updateFeatureName,
  addFeature,
  setMembers,
  updateMember,
  addMember,
  deleteMember,
  deactivateMember,
  setIntegrations,
  deleteIntegration,
  setWorkspaceExplores,
  addWorkspaceExplore,
  deleteWorkspaceExplore,
  clearWorkspace,
  updateChatConfig,
  addExpandedThought,
  removeExpandedThought,
  setDataSourceOptions,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
