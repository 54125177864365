export const PROMPT_SECTION_HEIGHT = 200;
export const PROMPT_SECTION_GRADIENT_HEIGHT = 30;
export const BLOCK_MAX_WIDTH = 640;
export const CHAT_ICON_SIZE = 24;
export const USER_MESSAGE_ID = 'user';
export const AI_MESSAGE_ID = 'ai';
export const WELCOME_MESSAGE_ID = 'welcome';
export const CHAT_MESSAGE_SPACING = 5;
export const WINDOW_HEIGHT_TO_COLLAPSE_QUESTIONS = 800;
export const AUTOMATIC_EXPLORE_LABEL = 'Automatic (default)';
export const AUTOMATIC_EXPLORE_VALUE = '_AUTOMATIC_';
