import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import { NextPage } from 'next';

import { store } from '../store';
import { Provider as StoreProvider } from 'react-redux';
import client from '../lib/apollo/apolloClient';

import NoSsr from '@mui/material/NoSsr';

import { AuthGuard } from '../components/AuthGuard';
import PickerDataProvider from '../components/PickerDataProvider';
import { DialogProvider } from '../hooks/useDialog';

import '../styles/index.css';
import { UserType } from '../store/slices/session';
import MuiThemeProvider from '../themes/index';
import { DrawerProvider } from '../hooks/useDrawer';
import SnackbarProvider from '../components/SnackbarProvider';
import EnvironmentProvider from '../components/EnvironmentProvider';
import WorkspaceProvider from '../components/WorkspaceProvider';
import EmbeddingProvider from '../contexts/EmbeddingProvider';
import LogRocketProvider from '../contexts/LogRocketProvider';
import RootLayout from '../components/Layout/RootLayout';

// @TODO: try to fix
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean;
  requiredUserType?: UserType;
};

export default function MyApp({
  Component,
  pageProps,
  router,
}: AppProps & { Component: NextApplicationPage }) {
  const useRootLayout = pageProps.useRootLayout !== false;
  const workspaceId = router.query.workspaceId as string;

  return (
    <NoSsr>
      <StoreProvider store={store}>
        <ApolloProvider client={client}>
          <MuiThemeProvider>
            <SnackbarProvider>
              <PickerDataProvider>
                <EmbeddingProvider>
                  <AuthGuard
                    requireAuth={Component.requireAuth || undefined}
                    requiredUserType={Component.requiredUserType || undefined}
                  >
                    <LogRocketProvider>
                      <EnvironmentProvider>
                        <WorkspaceProvider>
                          <DrawerProvider>
                            <DialogProvider>
                              {useRootLayout ? (
                                <RootLayout>
                                  <Component
                                    {...pageProps}
                                    workspaceId={workspaceId}
                                  />
                                </RootLayout>
                              ) : (
                                <Component {...pageProps} />
                              )}
                            </DialogProvider>
                          </DrawerProvider>
                        </WorkspaceProvider>
                      </EnvironmentProvider>
                    </LogRocketProvider>
                  </AuthGuard>
                </EmbeddingProvider>
              </PickerDataProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </ApolloProvider>
      </StoreProvider>
    </NoSsr>
  );
}
