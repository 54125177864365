import {
  useEffect,
  ReactNode,
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react';
import dynamic from 'next/dynamic';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';
import { Routes } from '@madeinventive/core-types';

//components
import { Stack } from '@mui/material';
import Modals from '../modals';
import Header from '../Header';
import PoweredByRibbon from '../PoweredBy/Ribbon';
import ErrorBoundary from '../ErrorBoundary';

// hooks and context
import { useAppDispatch, useSessionInfo } from '../../hooks';
import { setActiveModalSlice } from '../../store/slices/modal';
import { useEmbeddingContext } from '../../contexts/EmbeddingProvider';
import { EnvironmentContext } from '../EnvironmentProvider';
import WorkspacePageLayout from './WorkspaceLayout';

// lazy load layouts
const SettingsTopBar = dynamic(
  () => import('./LayoutComponents/SettingsTopBar'),
);
const WorkspaceSelectorBar = dynamic(() => import('../WorkspaceSelectorBar'));

const SettingsSidebar = dynamic(
  () => import('./LayoutComponents/SettingsSidebar'),
);

const WorkspaceSettingsLayout = dynamic(
  () => import('./WorkspaceSettingsLayout'),
);

const OrganizationSettingsLayout = dynamic(
  () => import('./OrganizationSettingsLayout'),
);

interface LayoutProps {
  children?: ReactNode;
}

const RootLayout = ({ children }: LayoutProps) => {
  const router = useRouter();
  const storeDispatch = useAppDispatch();
  const { pathname } = useRouter();
  const { isEmbedded } = useEmbeddingContext();
  const { isSignedIn, userType, email, firstName, lastName } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);

  const showC1Settings =
    (!isEmbedded && userType === 'EnvironmentMember') ||
    userType === 'SystemUser';
  const isWorkspace =
    pathname === '/workspace' || pathname.includes('/workspace/');
  const workspaceId = router.query.workspaceId as string;
  const isWorkspaceSettings = pathname.includes('/workspace-settings');
  const isOrganizationSettings = pathname.includes('/organization-settings');
  const [shouldShowPoweredBy, setShouldShowPoweredBy] = useState(false);

  useEffect(() => {
    if (environment) {
      setShouldShowPoweredBy(!environment.whiteLabeled);
    }
  }, [environment]);

  const workspaceSelectionCallback = useCallback(
    (workspaceId: string) => {
      if (isWorkspace) {
        // if the page is workspace page, `workspace/[workspaceId]`,
        // we need to update the url
        router.replace(Routes.workspace(workspaceId), undefined, {
          shallow: true,
        });
      }
    },
    [isWorkspace, router],
  );

  useEffect(() => {
    if (isSignedIn) {
      LogRocket.identify(email, {
        name: `${firstName} ${lastName}`,
        email: email,
        userType: userType,
        env: process.env.NEXT_PUBLIC_ENV ?? 'local',
      });
    }
  }, [email, firstName, isSignedIn, lastName, userType]);

  // This useEffect can be deleted when the settings modal completed removed for C2 users
  useEffect(() => {
    // open settings modal if query param is present
    const openSettingsModal = () => {
      storeDispatch(
        setActiveModalSlice({
          activeModal: 'settings_gallery',
        }),
      );
    };

    if (router.query.settings) {
      openSettingsModal();
    }
  }, [router.query.settings, storeDispatch]);

  const showWorkspaceSelector = isWorkspace || isWorkspaceSettings;
  const showC1SettingsSidebar = isWorkspaceSettings || isOrganizationSettings;

  const SettingsLayout = useMemo(() => {
    if (isWorkspaceSettings) {
      return WorkspaceSettingsLayout;
    } else if (isOrganizationSettings) {
      return OrganizationSettingsLayout;
    } else {
      return function AnonymousComponent({
        children,
      }: {
        children: ReactNode;
      }) {
        return <>{children}</>;
      };
    }
  }, [isOrganizationSettings, isWorkspaceSettings]);

  return (
    <>
      <Header />
      <Modals />
      <Stack id='view' height='100vh' overflow='hidden'>
        {showC1Settings && <SettingsTopBar />}
        <Stack
          id='organization-level-content-container'
          flex={1}
          overflow='hidden'
        >
          {showC1Settings && showWorkspaceSelector && (
            <WorkspaceSelectorBar
              selectionCallback={workspaceSelectionCallback}
            />
          )}
          <Stack
            id='workspace-level-content-container'
            direction='row'
            flex={1}
            overflow='hidden'
          >
            {showC1SettingsSidebar && (
              <SettingsSidebar
                currentPath={pathname}
                group={isWorkspaceSettings ? 'workspace' : 'organization'}
              />
            )}
            <Stack
              id='page-content-container'
              flex={1}
              width='100%'
              overflow='hidden'
              position='relative'
              alignItems='center'
            >
              <SettingsLayout>
                <ErrorBoundary>
                  {isWorkspace ? (
                    <WorkspacePageLayout workspaceId={workspaceId}>
                      {children}
                    </WorkspacePageLayout>
                  ) : (
                    children
                  )}
                </ErrorBoundary>
              </SettingsLayout>
            </Stack>
          </Stack>
        </Stack>

        {isEmbedded && shouldShowPoweredBy && <PoweredByRibbon />}
      </Stack>
    </>
  );
};

export default RootLayout;
