import { useCallback, useMemo } from 'react';
import {
  EmailAttachmentV1,
  AttachmentType,
  FieldDatumV1,
  NormalizedConditionExpression,
  Routes,
} from '@madeinventive/core-types';

import {
  Feature,
  FeatureState,
  FeatureType,
  TemplateJoinType,
  useCreateFeatureTemplateMutation,
  useCreateWorkspaceFeatureMutation,
  useEditFeatureTemplateMutation,
  useEditWorkspaceFeatureMutation,
  useFeatureLazyQuery,
  useFeatureTemplateLazyQuery,
  useSetWorkspaceFeatureStateMutation,
} from '../../generated/types';
import { useAppDispatch, useAppSelector } from '../store';
import { useSessionInfo } from '../session';
import WorkflowDataAdapter from './adapters/WorkflowDataAdapter';
import TableAppDataAdapter from './adapters/TableAppDataAdapter';
import { ACTION_MANIFEST_LOOKUP } from './ActionManifest';
import {
  checkFieldDataValidity,
  checkTriggerConditionsExist,
  checkTriggerConditionsValidity,
} from './validity';

import {
  ActionSpec,
  EmailInMemConfig,
  FeatureEditDataState,
  SlackInMemConfig,
  updateFeatureEditData,
  setFeatureEditData,
  resetFeatureEditData,
  addFieldDatum,
  updateFieldDatumAtIndex,
  deleteFieldDatumAtIndex,
  parseDynamicFieldsFor,
  addActionSpec,
  deleteActionSpecAtIndex,
  addTriggerCondition,
  deleteTriggerConditionAtIndex,
  resetFeatureEditDataConditions,
  updateTriggerConditionAtIndex,
  setEmailFieldByIndex,
  setSlackFieldByIndex,
  setActionIntegrationIdByIndex,
  updateActionSpecAtIndex,
  setActionLabelByIndex,
  PlaceholderEntity,
  toPlaceholderLookup,
  setFieldVisibilityInDetailView,
  setHasUnsavedChanges,
  updateFeatureMode,
  updateTriggerSchedule,
} from '../../store/slices/features';

import { ActionTriggerType } from '../../components/Workflow/types';

import {
  updateFeatureName,
  addFeature,
  updateFeature as updateFeatureFromWS,
} from '../../store/slices/workspace';
import { CustomBrandingState } from '../../store/slices/session';

import { useDialog } from '../useDialog';
import { DIALOG_IDS } from '../../components/registeredDialogs/dialogRegistry';

export enum LoadSource {
  DEFAULT = 'DEFAULT',
  TEMPLATE = 'TEMPLATE',
  DRAFT = 'DRAFT',
}

export enum SaveTarget {
  TEMPLATE = 'TEMPLATE',
  DRAFT = 'DRAFT',
}

export type FeatureLoadParams = {
  featureType: FeatureType;
  src: LoadSource;
  id?: string;
  workspaceId?: string; // only for workflow with component attachment
  attachedComponentIdFromUrl?: string; // only for workflow with component attachment
};

export type FeatureSaveParams = {
  featureType: FeatureType;
  actionTriggerType?: ActionTriggerType;
  target: SaveTarget;
  id?: string; // 'undefined' in the case of create, otherwise edit

  // 1) the workspace id if saving to DRAFT;
  // 2) either workspaceId or environmentId (depending on joinType)
  //    if saving to TEMPLATE
  entityId?: string;

  joinType?: TemplateJoinType;

  categoryId?: string; // used when saving as template

  nameOverride?: string;
  descriptionOverride?: string;

  saveFromTemplate?: boolean;
};

export const parseForActionDynamicInput = (data: FeatureEditDataState) => {
  const fieldSet = new Set<string>();
  data.actions.forEach((a) => parseDynamicFieldsFor(a, fieldSet));
  return Array.from(fieldSet);
};

export const parseFromConfig = (
  loadSource: Exclude<LoadSource, typeof LoadSource.DEFAULT>,
  type: FeatureType,
  jsonConfig: object,
) => {
  let parsedConfig: Omit<
    FeatureEditDataState,
    | 'id'
    | 'name'
    | 'description'
    | 'mode'
    | 'placeholderLookup'
    | 'hasUnsavedChanges'
  >;
  switch (type) {
    case FeatureType.WORKFLOW:
      parsedConfig = WorkflowDataAdapter.parseFromConfig(jsonConfig);
      break;
    case FeatureType.TABLE_APP:
      parsedConfig = TableAppDataAdapter.parseFromConfig(jsonConfig);
      break;
    default:
      throw new Error(
        `Load from ${
          loadSource === LoadSource.DRAFT ? 'draft' : 'template'
        } for feature type '${type}' not implemented`,
      );
  }
  return parsedConfig;
};

export const serializeToConfig = (
  saveTarget: SaveTarget,
  featureType: FeatureType,
  editData: FeatureEditDataState,
  customBranding?: CustomBrandingState,
) => {
  let configJSON: object;
  switch (featureType) {
    case FeatureType.WORKFLOW:
      configJSON = WorkflowDataAdapter.serializeToConfig(
        editData,
        customBranding,
      );
      break;
    case FeatureType.TABLE_APP:
      configJSON = TableAppDataAdapter.serializeToConfig(
        editData,
        customBranding,
      );
      break;
    default:
      throw new Error(
        `Save as ${
          saveTarget === SaveTarget.DRAFT ? 'draft' : 'template'
        } for feature type '${featureType}' not implemented`,
      );
  }
  return configJSON;
};

export const useFeature = () => {
  const workspaceId = useAppSelector(
    (store) => store.workspace.value.workspace?.id,
  );
  const featureEditData = useAppSelector(
    (store) => store.featureEditData.value,
  );
  const workspaceComponents = useAppSelector(
    (store) => store.workspaceComponents.value.components,
  );
  const { customBranding } = useSessionInfo();

  const storeDispatch = useAppDispatch();
  const [fetchFeatureDetails] = useFeatureLazyQuery();
  const [createWorkspaceFeature] = useCreateWorkspaceFeatureMutation();
  const [editWorkspaceFeature, { loading }] = useEditWorkspaceFeatureMutation();
  const [setFeatureState] = useSetWorkspaceFeatureStateMutation();

  const [fetchFeatureTemplateDetails] = useFeatureTemplateLazyQuery();
  const [createFeatureTemplate] = useCreateFeatureTemplateMutation();
  const [editFeatureTemplate] = useEditFeatureTemplateMutation();

  const isLive = featureEditData.mode === FeatureState.ACTIVE;

  const hasName =
    !!featureEditData.name && !featureEditData.name.startsWith(' ');
  const hasExplore = !!featureEditData.exploreId;

  // Checked before save, which requires all conditions be valid, but unresolved placeholders are ok
  // i.e. user can save features with unresolved placeholders and work on them later
  const hasUnresolvedConditions = useMemo(
    () =>
      !checkTriggerConditionsValidity(featureEditData.conditions, {
        skipPlaceholders: true,
        skipTooltip: true,
      }).result,
    [featureEditData.conditions],
  );

  // Checked before go live, which requires all conditions be valid, and all placeholders resolved
  const hasInvalidConditions = useMemo(
    () =>
      !checkTriggerConditionsValidity(featureEditData.conditions, {
        skipTooltip: true,
      }).result,
    [featureEditData.conditions],
  );

  const hasNoConditions = useMemo(
    () => !checkTriggerConditionsExist(featureEditData.conditions).result,
    [featureEditData.conditions],
  );

  const hasInvalidActions = useMemo(() => {
    for (const action of featureEditData.actions) {
      const manifest = ACTION_MANIFEST_LOOKUP[action.kind];
      if (
        !manifest ||
        (manifest.checkValidity && !manifest.checkValidity(action).result)
      )
        return true;
    }
    return false;
  }, [featureEditData.actions]);

  const hasInvalidFieldData = useMemo(
    () =>
      !checkFieldDataValidity(featureEditData.fieldData, {
        skipTooltip: true,
      }).result,
    [featureEditData.fieldData],
  );

  const loadEditDataWith = useCallback(
    (
      id: string,
      loadSource: Exclude<LoadSource, typeof LoadSource.DEFAULT>,
      type: FeatureType,
      name: string,
      description: string,
      jsonConfig: object,
      placeholders: PlaceholderEntity[],
      state?: FeatureState,
    ) => {
      const parsedConfig = parseFromConfig(loadSource, type, jsonConfig);
      if (parsedConfig) {
        const editDataState: FeatureEditDataState = {
          name,
          description,
          mode: state || FeatureState.DRAFT,
          placeholderLookup: toPlaceholderLookup(placeholders),
          ...parsedConfig,
          id,
        };
        storeDispatch(setFeatureEditData(editDataState));
      }
    },
    [storeDispatch],
  );

  const loadFeatureFrom = useCallback(
    async (params: FeatureLoadParams) => {
      const { featureType, src, id, workspaceId, attachedComponentIdFromUrl } =
        params;
      switch (src) {
        case LoadSource.DEFAULT:
          {
            storeDispatch(resetFeatureEditData());
            if (featureType === FeatureType.TABLE_APP) {
              // Under construction
              // initialize the page with 2 empty column
              storeDispatch(
                addFieldDatum({
                  variable: {
                    field: '',
                    normalizedType: '',
                  },
                  label: 'Column 1',
                }),
              );
              storeDispatch(
                addFieldDatum({
                  variable: {
                    field: '',
                    normalizedType: '',
                  },
                  label: 'Column 2',
                }),
              );
            }

            // set default scheduled workflow with attachment
            if (
              featureType === FeatureType.WORKFLOW &&
              attachedComponentIdFromUrl
            ) {
              const component = workspaceComponents?.find(
                (c) => c.id === attachedComponentIdFromUrl,
              );

              if (component && workspaceId) {
                const protocol = window.location.protocol;
                const baseURL = window.location.host;
                const link = Routes.workspaceComponent(
                  workspaceId,
                  component.id,
                );
                const externalLink = `${protocol}//${baseURL}${link}`;

                const attachment: EmailAttachmentV1 = {
                  type: AttachmentType.COMPONENT,
                  spec: {
                    componentId: component.id,
                  },
                };

                const emailSpec: EmailInMemConfig = {
                  to: '',
                  subject: `Scheduled report for ${component.name}`,
                  body: `<p>Attached is the ${component.name} report. </p><p>If you have permission to manage this alert, you can do so <a href='${externalLink}'>here</a></p>`,
                  buttonUrl: externalLink,
                  buttonText: 'See the live data',
                  attachments: [attachment],
                };

                const defaultEmailActionSpec: ActionSpec = {
                  kind: 'Email/v2.0',
                  spec: emailSpec,
                };

                storeDispatch(
                  updateFeatureEditData({ actionTriggerType: 'schedule' }),
                );
                storeDispatch(
                  updateFeatureEditData({ cronSchedule: '0 0 6 * * *' }),
                );
                storeDispatch(addActionSpec(defaultEmailActionSpec));
              }
            }
          }
          break;
        case LoadSource.DRAFT:
          {
            if (id === undefined) {
              throw new Error(`Feature id is required when loading from draft`);
            }

            const result = await fetchFeatureDetails({
              variables: { id: id },
            });
            const data = result?.data;
            if (data?.node?.__typename === 'Feature') {
              if (featureType !== data.node.type) {
                throw new Error(`Feature '${id}' is not a '${featureType}'`);
              }
              loadEditDataWith(
                id,
                src,
                featureType,
                data.node.name,
                data.node.description || '',
                data.node.configJSON,
                data.node.placeholders.map((p) => ({
                  ...p,
                  description:
                    p.description === null ? undefined : p.description,
                })),
                data.node.state,
              );
            }
          }
          break;
        case LoadSource.TEMPLATE:
          {
            if (id === undefined) {
              throw new Error(
                `Template id is required when loading from feature template`,
              );
            }

            const result = await fetchFeatureTemplateDetails({
              variables: { id: id },
            });

            const data = result?.data;
            if (data?.node?.__typename === 'FeatureTemplate') {
              if (featureType !== data.node.type) {
                throw new Error(`Template '${id}' is not a '${featureType}'`);
              }
              loadEditDataWith(
                id,
                src,
                featureType,
                data.node.name,
                data.node.description || '',
                data.node.configJSON,
                [],
              );
            }
          }
          break;
      }
    },
    [
      fetchFeatureDetails,
      fetchFeatureTemplateDetails,
      loadEditDataWith,
      storeDispatch,
      workspaceComponents,
    ],
  );

  const setFeatureName = useCallback(
    (name: string) => {
      storeDispatch(updateFeatureEditData({ name }));
      // this updates the name in the sidebar feature list
      storeDispatch(updateFeatureName({ id: featureEditData.id, name }));
    },
    [featureEditData.id, storeDispatch],
  );

  const updateFeatureNameAndSave = useCallback(
    async (name: string) => {
      await editWorkspaceFeature({
        variables: {
          input: {
            featureId: featureEditData.id,
            name,
          },
        },
      });
      storeDispatch(updateFeatureEditData({ name }));
      // this updates the name in the sidebar feature list
      storeDispatch(updateFeatureName({ id: featureEditData.id, name }));
    },
    [editWorkspaceFeature, featureEditData.id, storeDispatch],
  );

  const saveFeatureAs = useCallback(
    async (params: FeatureSaveParams) => {
      const {
        featureType,
        target,
        id, // feature id
        entityId,
        joinType,
        categoryId,
        nameOverride,
        descriptionOverride,
        saveFromTemplate,
      } = params;

      switch (target) {
        case SaveTarget.DRAFT:
          {
            const configJSON = serializeToConfig(
              target,
              featureType,
              featureEditData,
              customBranding,
            );

            if (configJSON) {
              if (id) {
                try {
                  // update existing
                  const input = {
                    featureId: id,
                    name: nameOverride || featureEditData.name,
                    description:
                      descriptionOverride || featureEditData.description,
                    config: configJSON,
                  };
                  const result = await editWorkspaceFeature({
                    variables: {
                      input: input,
                    },
                  });

                  const updatedFeature =
                    result?.data?.editWorkspaceFeature?.feature;
                  if (updatedFeature) {
                    // edit successful, update the cache copy for change detection
                    storeDispatch(setHasUnsavedChanges(false));
                    // update the feature name
                    // in case the returned name is different from the user input
                    if (updatedFeature.name !== featureEditData.name) {
                      setFeatureName(updatedFeature.name);
                    }
                  }
                  return updatedFeature;
                } catch {
                  // Nothing to do here just make sure any ApolloError is handled.
                  // Showing the error to user is already handled by the error link.
                }
              } else if (entityId) {
                // create new
                try {
                  const result = await createWorkspaceFeature({
                    variables: {
                      input: {
                        workspaceId: entityId,
                        name:
                          nameOverride || featureEditData.name || 'Untitled',
                        description:
                          descriptionOverride || featureEditData.description,
                        type: featureType,
                        config: configJSON,
                        fromTemplate: saveFromTemplate,
                      },
                    },
                  });

                  const newFeature =
                    result?.data?.createWorkspaceFeature?.feature;
                  if (newFeature) {
                    // create successful, update the cache copy for change detection
                    storeDispatch(addFeature(newFeature as Feature));
                    // update the feature name
                    // in case the returned name is different from the user input
                    if (newFeature.name !== featureEditData.name) {
                      setFeatureName(newFeature.name);
                    }
                  }
                  return newFeature;
                } catch {
                  // Nothing to do here just make sure any ApolloError is handled.
                  // Showing the error to user is already handled by the error link.
                }
              }
              throw new Error(
                `Workspace id is required when saving a new draft`,
              );
            }
          }
          break;
        case SaveTarget.TEMPLATE:
          {
            const configJSON = serializeToConfig(
              target,
              featureType,
              featureEditData,
              /* leave out custom branding, as it's not meant for templates */
            );

            if (configJSON) {
              if (id) {
                try {
                  // update existing
                  const result = await editFeatureTemplate({
                    variables: {
                      input: {
                        templateId: id,
                        name: nameOverride || featureEditData.name,
                        description:
                          descriptionOverride || featureEditData.description,
                        config: configJSON,
                        categoryId,
                      },
                    },
                  });
                  storeDispatch(setHasUnsavedChanges(false));
                  return result?.data?.editFeatureTemplate.template;
                } catch {
                  // Nothing to do here just make sure any ApolloError is handled.
                }
              } else if (entityId && joinType) {
                // create new
                try {
                  const result = await createFeatureTemplate({
                    variables: {
                      input: {
                        entityId,
                        joinType,
                        name: nameOverride || featureEditData.name,
                        description:
                          descriptionOverride || featureEditData.description,
                        type: featureType,
                        config: configJSON,
                        categoryId,
                      },
                    },
                  });
                  storeDispatch(setHasUnsavedChanges(false));
                  return result?.data?.createFeatureTemplate.template;
                } catch {
                  // Nothing to do here just make sure any ApolloError is handled.
                }
              }
              if (!entityId || !joinType) {
                throw new Error(
                  `Entity id and the join type are both required when saving a template`,
                );
              }
            }
          }
          break;
      }
    },
    [
      featureEditData,
      customBranding,
      createFeatureTemplate,
      createWorkspaceFeature,
      editFeatureTemplate,
      editWorkspaceFeature,
      storeDispatch,
      setFeatureName,
    ],
  );

  const setFeatureEditMode = useCallback(
    async (id: string, mode: FeatureState) => {
      try {
        const result = await setFeatureState({
          variables: {
            input: {
              featureId: id,
              newState: mode,
            },
          },
        });

        const data = result?.data;
        const errors =
          data?.setWorkspaceFeatureState?.errors.map((e) => e.message) || [];
        const updatedFeature = data?.setWorkspaceFeatureState?.updated;
        if (errors.length === 0 && updatedFeature) {
          storeDispatch(updateFeatureFromWS(updatedFeature as Feature));
          storeDispatch(updateFeatureMode(mode));
          storeDispatch(setHasUnsavedChanges(false));
          return true;
        }
        return errors;
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
      }
      return false;
    },
    [setFeatureState, storeDispatch],
  );

  const setFeatureDescription = useCallback(
    (description: string) =>
      storeDispatch(updateFeatureEditData({ description })),
    [storeDispatch],
  );

  const setExploreId = useCallback(
    (envExploreId: string) =>
      storeDispatch(updateFeatureEditData({ exploreId: envExploreId })),
    [storeDispatch],
  );

  const setActionIntegrationIdAt = useCallback(
    (index: number, integrationId: string) =>
      storeDispatch(
        setActionIntegrationIdByIndex({
          index,
          integrationId,
        }),
      ),
    [storeDispatch],
  );

  const setActionLabelAt = useCallback(
    (index: number, actionLabel: string) =>
      storeDispatch(
        setActionLabelByIndex({
          index,
          actionLabel,
        }),
      ),
    [storeDispatch],
  );

  const addAction = useCallback(
    (spec: ActionSpec) => storeDispatch(addActionSpec(spec)),
    [storeDispatch],
  );

  const updateActionAt = useCallback(
    (index: number, spec: ActionSpec) =>
      storeDispatch(updateActionSpecAtIndex({ index, value: spec })),
    [storeDispatch],
  );

  const deleteActionAt = useCallback(
    (actionIndex: number) =>
      storeDispatch(deleteActionSpecAtIndex(actionIndex)),
    [storeDispatch],
  );

  const setEmailFieldAt = useCallback(
    (index: number, fieldName: keyof EmailInMemConfig, fieldValue: string) =>
      storeDispatch(
        setEmailFieldByIndex({
          index,
          fieldName,
          fieldValue,
        }),
      ),
    [storeDispatch],
  );

  const setSlackFieldAt = useCallback(
    (
      index: number,
      fieldName: keyof Omit<SlackInMemConfig, 'actionType'>,
      fieldValue: string,
    ) =>
      storeDispatch(
        setSlackFieldByIndex({
          index,
          fieldName,
          fieldValue,
        }),
      ),
    [storeDispatch],
  );

  const setActionTriggerType = useCallback(
    (actionTriggerType: ActionTriggerType) =>
      storeDispatch(updateFeatureEditData({ actionTriggerType })),
    [storeDispatch],
  );

  const setCronSchedule = useCallback(
    (cronSchedule: string) =>
      storeDispatch(updateTriggerSchedule(cronSchedule)),
    [storeDispatch],
  );

  const addCondition = useCallback(
    () => storeDispatch(addTriggerCondition(undefined)),
    [storeDispatch],
  );

  const deleteConditionAt = useCallback(
    (index: number) => storeDispatch(deleteTriggerConditionAtIndex(index)),
    [storeDispatch],
  );

  const resetConditions = useCallback(
    () => storeDispatch(resetFeatureEditDataConditions(undefined)),
    [storeDispatch],
  );

  const updateConditionAt = useCallback(
    (index: number, condition: NormalizedConditionExpression) =>
      storeDispatch(updateTriggerConditionAtIndex({ index, value: condition })),
    [storeDispatch],
  );

  const addColumnField = useCallback(
    () =>
      storeDispatch(
        addFieldDatum({
          variable: {
            field: '',
            normalizedType: '',
          },
          label: 'New column',
        }),
      ),
    [storeDispatch],
  );

  const updateColumnFieldAt = useCallback(
    (index: number, fieldDatum: FieldDatumV1) =>
      storeDispatch(updateFieldDatumAtIndex({ index, value: fieldDatum })),
    [storeDispatch],
  );

  const deleteColumnAt = useCallback(
    (index: number) => storeDispatch(deleteFieldDatumAtIndex(index)),
    [storeDispatch],
  );

  const updateFieldVisibilityInDetailView = useCallback(
    (field: string, isVisible: boolean) => {
      storeDispatch(
        setFieldVisibilityInDetailView({
          field,
          isVisible,
        }),
      );
    },
    [storeDispatch],
  );

  const unsavedChangesDialogContentProps = useMemo(
    () => ({
      content: {
        heading: `Would you like to save or discard changes to ‘${
          featureEditData.name || `Untitled`
        }’ ?`,
      },
    }),
    [featureEditData.name],
  );

  const { showDialog } = useDialog({
    id: DIALOG_IDS.CONFIRMATION,
    title: 'You have unsaved changes',
    contentProps: unsavedChangesDialogContentProps,
  });

  const showUnsavedChangesDialog = useCallback(
    (deferredAction: () => void) => {
      showDialog({
        secondaryAction: {
          text: 'Discard',
          action: () => {
            // clear hasUnsavedChange state
            storeDispatch(setHasUnsavedChanges(false));
            // revert the name change on the sidebar
            if (
              featureEditData.prevName &&
              featureEditData.prevName !== featureEditData.name
            ) {
              storeDispatch(
                updateFeatureName({
                  id: featureEditData.id,
                  name: featureEditData.prevName,
                }),
              );
            }
            if (deferredAction) {
              deferredAction();
            }
          },
        },
        primaryAction: {
          text: 'Save',
          asyncAction: async () => {
            try {
              await saveFeatureAs({
                featureType: featureEditData.featureType,
                actionTriggerType:
                  featureEditData.actionTriggerType ?? 'condition',
                target:
                  featureEditData.mode === FeatureState.DRAFT
                    ? SaveTarget.DRAFT
                    : SaveTarget.TEMPLATE,
                id: featureEditData.id,
                entityId: workspaceId,
                nameOverride: featureEditData.name,
              }).then(() => {
                if (deferredAction) {
                  storeDispatch(setHasUnsavedChanges(false));
                  deferredAction();
                }
              });
              return true;
            } catch (e) {
              return false;
            }
          },
        },
      });
    },
    [
      showDialog,
      featureEditData.featureType,
      featureEditData.name,
      featureEditData.prevName,
      featureEditData.id,
      featureEditData.mode,
      featureEditData.actionTriggerType,
      storeDispatch,
      saveFeatureAs,
      workspaceId,
    ],
  );

  return {
    featureEditData,
    isSavingFeature: loading,
    isLive,
    hasName,
    hasExplore,
    hasUnresolvedConditions,
    hasInvalidConditions,
    hasNoConditions,
    hasInvalidActions,
    hasInvalidFieldData,

    loadFeatureFrom,
    saveFeatureAs,
    setFeatureEditMode,

    updateFeatureNameAndSave,
    setFeatureName,
    setFeatureDescription,

    setExploreId,

    // workflow action trigger
    setActionTriggerType,

    // scheduled workflow
    setCronSchedule,

    // conditional workflow
    addCondition,
    deleteConditionAt,
    resetConditions,
    updateConditionAt,

    setActionIntegrationIdAt,
    setActionLabelAt,

    addAction,
    updateActionAt,
    deleteActionAt,
    setEmailFieldAt,
    setSlackFieldAt,

    // table app
    addColumnField,
    updateColumnFieldAt,
    deleteColumnAt,

    updateFieldVisibilityInDetailView,

    showUnsavedChangesDialog,
  };
};
