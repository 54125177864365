import { createTheme, ThemeProvider } from '@mui/material/styles';
import { darken, lighten } from '@mui/material';

import { useSessionInfo } from '../hooks';
import { palette } from './palette';
import { typography } from './typography';
import { components } from './components';

interface MuiThemeProviderProps {
  children: React.ReactNode;
}

let theme = createTheme({
  palette: palette,
  typography: typography,
  components: components,
});

const MuiThemeProvider = ({ children }: MuiThemeProviderProps) => {
  const { customBranding } = useSessionInfo();
  const modifiedPalette = palette;

  if (customBranding?.themeColor) {
    const defaultPrimary = palette.primary;
    const primary = {
      ...defaultPrimary,
      wash: lighten(customBranding.themeColor, 0.7),
      light: lighten(customBranding.themeColor, 0.2),
      main: customBranding?.themeColor,
      dark: darken(customBranding.themeColor, 0.2),
    };
    modifiedPalette.primary = primary;
  }

  theme = createTheme({
    palette: modifiedPalette,
    typography: typography,
    components: components,
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { theme };

export default MuiThemeProvider;
