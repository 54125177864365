import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnvironmentMember } from '../../generated/types';

interface EnvironmentMembersState {
  environmentMembers: EnvironmentMember[];
}

const initialState: EnvironmentMembersState = {
  environmentMembers: [],
};

const environmentMembersSlice = createSlice({
  name: 'environmentMembers',
  initialState: {
    value: initialState,
  },
  reducers: {
    setEnvironmentMembers: (
      state,
      action: PayloadAction<EnvironmentMember[]>,
    ) => {
      state.value.environmentMembers = action.payload;
    },
    addEnvironmentMember: (state, action: PayloadAction<EnvironmentMember>) => {
      state.value.environmentMembers.push(action.payload);
    },
    updateEnvironmentMember: (
      state,
      action: PayloadAction<EnvironmentMember>,
    ) => {
      state.value.environmentMembers = state.value.environmentMembers.map(
        (member) => (member.id === action.payload.id ? action.payload : member),
      );
    },
    deactivateEnvironmentMember: (state, action: PayloadAction<string>) => {
      state.value.environmentMembers = state.value.environmentMembers.map(
        (member) =>
          member.id === action.payload
            ? { ...member, status: 'DEACTIVATED' }
            : member,
      );
    },
  },
});

export const {
  setEnvironmentMembers,
  addEnvironmentMember,
  updateEnvironmentMember,
  deactivateEnvironmentMember,
} = environmentMembersSlice.actions;

export default environmentMembersSlice.reducer;
