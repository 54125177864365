import { PaletteOptions } from '@mui/material';
import { alpha, lighten } from '@mui/system';

export const // primary
  primary = '#D4D6EC',
  primaryLight = '#DCDEEF',
  primaryDark = '#9495A5',
  // status colors
  // error
  errorDark = '#993A2C',
  errorMain = '#D05340',
  errorWash = '#FFEBED',
  // warning
  warningDark = '#CE8308',
  warningMain = '#EFD263',
  warningWash = '#FFF9E2',
  // success
  successDark = '#385B4A',
  successMain = '#5B8A72',
  successWash = '#EDFCEF',
  // info
  infoDark = '#1F305E',
  infoMain = '#6082B6',
  infoWash = '#E8F3FF',
  // new neutrals
  shadow = '#00046A',
  white = '#FFFFFF',
  black = '#03001E',
  b1 = '#404059',
  b2 = '#696B8E',
  b3 = '#D4D6EC',
  b4 = '#E7E9F7',
  b5 = '#ECEEF6',
  b6 = '#F8F9FE',
  // text
  textPrimary = black,
  textSecondary = b1,
  textTertiary = b2,
  textDisabled = b2,
  textInverted = white,
  // border
  borderActionable = b3,
  borderDisabled = b4,
  borderLight = b5,
  borderActive = black,
  // icon
  iconMain = b1,
  iconDisabled = b3,
  iconActive = black,
  iconInverted = white,
  // background
  bgPaper = white,
  bgDefault = b6,
  backgroundDisabled = b6,
  backgroundInverted = black,
  backgroundOverlay = alpha(black, 0.4),
  backgroundGradient =
    'linear-gradient(90deg, rgba(212, 214, 236, 0.08) 0%, rgba(79, 82, 234, 0.08) 56%, rgba(131, 103, 199, 0.08) 100%)';

export const palette: PaletteOptions = {
  primary: {
    dark: primaryDark,
    main: primary,
    light: primaryLight,
    wash: lighten(primaryLight, 0.8),
    contrastText: black,
  },
  // same as neutral
  secondary: {
    dark: b2,
    main: b3,
    light: b4,
    wash: b5,
    contrastText: black,
  },
  // same as secondary
  neutral: {
    dark: b2,
    main: b3,
    light: b4,
    wash: b5,
    contrastText: black,
  },
  error: {
    dark: errorDark,
    main: errorMain,
    light: lighten(errorMain, 0.4),
    wash: errorWash,
    contrastText: white,
  },
  warning: {
    dark: warningDark,
    main: warningMain,
    light: lighten(warningMain, 0.4),
    wash: warningWash,
    contrastText: white,
  },
  success: {
    dark: successDark,
    main: successMain,
    light: lighten(successMain, 0.4),
    wash: successWash,
    contrastText: white,
  },
  info: {
    dark: infoDark,
    main: infoMain,
    light: lighten(infoMain, 0.4),
    wash: infoWash,
    contrastText: white,
  },
  text: {
    primary: textPrimary,
    secondary: textSecondary,
    tertiary: textTertiary,
    disabled: textDisabled,
    inverted: textInverted,
  },
  border: {
    actionable: borderActionable,
    disabled: borderDisabled,
    light: borderLight,
    active: borderActive,
  },
  icon: {
    main: iconMain,
    disabled: iconDisabled,
    active: iconActive,
    inverted: iconInverted,
  },
  background: {
    paper: bgPaper,
    default: bgDefault,
    disabled: backgroundDisabled,
    inverted: backgroundInverted,
    overlay: backgroundOverlay,
    gradient: backgroundGradient,
  },
  neutrals: {
    shadow,
    black,
    b1,
    b2,
    b3,
    b4,
    b5,
    b6,
    white,
  },
  accents: {
    accent1: '#8C8FBE',
    accent2: '#4B4F6F',
    accent3: '#61A1A2',
    accent4: '#65997F',
    accent5: '#53706F',
    accent6: '#6587A6',
    accent7: '#6874A3',
    accent8: '#807198',
    accent9: '#63537E',
    accent10: '#9D6995',
    accent11: '#BB7E8C',
    accent12: '#673A3F',
  },
  heatmap: {
    heatmap1: '#E3FFFF',
    heatmap2: '#ACD5D6',
    heatmap3: '#61A1A2',
    heatmap4: '#006D6F',
    heatmap5: '#006D6F',
  },
  diverging: {
    diverging1: '#3E0336',
    diverging2: '#9D6995',
    diverging3: '#DFC6E7',
    diverging4: '#C4E9EA',
    diverging5: '#61A1A2',
    diverging6: '#006D6F',
  },
  action: {
    level1: alpha(black, 0.04), // table odd row
    level2: alpha(black, 0.08), // table row hover
    level3: alpha(black, 0.12), // table row selected
  },
};
