import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import FormField, { FormFieldProps } from '../FormField';

export interface TextFieldProps extends Omit<FormFieldProps, 'onChange'> {
  value?: string;
  placeholder?: string;
  inputProps?: OutlinedInputProps;
  multiline?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const TextField = (props: TextFieldProps) => {
  const { value, sx, placeholder, inputProps, multiline, onChange, ...rest } =
    props;

  return (
    <FormField {...rest}>
      <OutlinedInput
        sx={sx}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        multiline={multiline}
        {...inputProps}
      />
    </FormField>
  );
};

export default TextField;
