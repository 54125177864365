import { useRef } from 'react';
import {
  Formik,
  Form,
  FormikConfig,
  FormikValues,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from 'formik';
import { Stack } from '@mui/material';
import Button from '../Button';

export type BaseButtonConfig = {
  text: string;
  disabledWhenStepClean?: boolean; // if true, the button will be disabled when the step from is clean
  disabledWhenAllStepsClean?: boolean; // if true, the button will be disabled when the all steps are clean
};

export type PrimaryButtonConfig = BaseButtonConfig & {
  action: FormikConfig<FormikValues>['onSubmit'];
};

export type SecondaryButtonConfig = BaseButtonConfig & {
  action: (values: FormikValues) => void;
  shouldDiscardStepChange?: boolean; // if true, the step form values will be reset when the button is clicked
};

interface SteppedFormLayoutProps<FormikValues> {
  index: number;
  id: string;
  goBack: () => void;
  activeIndex: number;
  children: React.ReactNode;
  initialFormValues: FormikValues;
  validationSchema?: FormikConfig<FormikValues>['validationSchema'];
  primaryButtonConfig: PrimaryButtonConfig;
  secondaryButtonConfig: SecondaryButtonConfig;
  onDirtyChange?: (isDirty: boolean) => void;
  onResetForm?: () => void;
  isFormDirty?: boolean;
}

const SteppedFormLayout = ({
  index,
  id,
  goBack,
  activeIndex,
  initialFormValues,
  validationSchema,
  primaryButtonConfig,
  secondaryButtonConfig,
  children,
  onDirtyChange,
  onResetForm,
  isFormDirty,
}: SteppedFormLayoutProps<FormikValues>) => {
  const isVisible = index === activeIndex;
  const isFirstStep = index === 0;

  const handlePrimaryAction: FormikConfig<FormikValues>['onSubmit'] = async (
    values,
    helpers,
  ) => {
    await primaryButtonConfig.action(values, helpers);
  };

  const handleSecondaryAction = async (
    validateForm: () => Promise<FormikErrors<FormikValues>>,
    setTouched: (touched: FormikTouched<FormikValues>) => void,
    values: FormikValues,
  ) => {
    // Create an object with all fields set to true
    const allFieldsTouched = Object.keys(values).reduce(
      (acc: Record<string, boolean>, field: string) => {
        acc[field] = true;
        return acc;
      },
      {},
    );

    // Set all fields to touched
    setTouched(allFieldsTouched);

    // Validate the form
    const result = await validateForm();

    // if there are errors, do not proceed
    if (Object.keys(result).length > 0) {
      return;
    }

    // Call secondary action
    secondaryButtonConfig.action(values);
  };

  if (!isVisible) return null;

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize={true} // reinitialize form values when initialValues change
      onSubmit={handlePrimaryAction}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnSubmit={true}
      submitOnEnter={false}
    >
      {({
        validateForm,
        setTouched,
        resetForm,
        values,
        isSubmitting,
        dirty,
      }) => {
        const customResetForm = () => {
          onResetForm && onResetForm();
          resetForm();
        };
        return (
          <Form
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            <DirtyStateHandler onDirtyChange={onDirtyChange} />
            <Stack id={id} direction='column' flex={1} width='100%'>
              {/* show form for step*/}
              <Stack flex={1} paddingTop={4} paddingX={2}>
                {children}
              </Stack>
              {/* navigation buttons  */}
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={1.5}
                paddingTop={4}
                paddingX={2}
                paddingBottom={2}
              >
                <Stack direction='row' alignItems='center'>
                  {!isFirstStep && (
                    <Button variant='text' onClick={goBack}>
                      Back
                    </Button>
                  )}
                </Stack>
                <Stack direction='row' alignItems='center' spacing={1.5}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      if (secondaryButtonConfig.shouldDiscardStepChange) {
                        customResetForm();
                      } else {
                        handleSecondaryAction(validateForm, setTouched, values);
                      }
                    }}
                    disabled={
                      (secondaryButtonConfig.disabledWhenStepClean && !dirty) ||
                      (secondaryButtonConfig.disabledWhenAllStepsClean &&
                        !isFormDirty)
                    }
                  >
                    {secondaryButtonConfig.text}
                  </Button>
                  <Button
                    type='submit'
                    variant='contained'
                    isLoading={isSubmitting}
                    disabled={
                      (primaryButtonConfig.disabledWhenStepClean && !dirty) ||
                      (primaryButtonConfig.disabledWhenAllStepsClean &&
                        !isFormDirty)
                    }
                  >
                    {primaryButtonConfig.text}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SteppedFormLayout;

const DirtyStateHandler = ({
  onDirtyChange,
}: {
  onDirtyChange?: (dirty: boolean) => void;
}) => {
  const { dirty } = useFormikContext();
  const dirtyRef = useRef(dirty);

  if (dirty !== dirtyRef.current) {
    dirtyRef.current = dirty;
    if (onDirtyChange) {
      onDirtyChange(dirty);
    }
  }

  return null;
};
