import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Environment,
  EnvironmentLookerDataModelExplore,
} from '../../generated/types';

interface EnvironmentState {
  environment?: Environment;
  envExplores?: EnvironmentLookerDataModelExplore[];
  wsListUpdateCounter: number;
}

const initialState: EnvironmentState = {
  environment: undefined,
  envExplores: undefined,
  wsListUpdateCounter: 0,
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState: {
    value: initialState,
  },
  reducers: {
    setEnvironment: (state, action: PayloadAction<Environment>) => {
      state.value.environment = action.payload;
    },
    setEnvironmentAPIKey: (state, action: PayloadAction<string>) => {
      if (state.value.environment) {
        state.value.environment.apiKey = action.payload;
      }
    },
    bumpWsListUpdateCounter: (state) => {
      state.value.wsListUpdateCounter =
        (state.value.wsListUpdateCounter ?? 0) + 1;
    },
    setEnvExplores: (
      state,
      action: PayloadAction<EnvironmentLookerDataModelExplore[]>,
    ) => {
      state.value.envExplores = action.payload;
    },
    addEnvExplore: (
      state,
      action: PayloadAction<EnvironmentLookerDataModelExplore>,
    ) => {
      state.value.envExplores?.push(action.payload);
    },
    updateEnvExplore: (
      state,
      action: PayloadAction<EnvironmentLookerDataModelExplore>,
    ) => {
      const index = state.value.envExplores?.findIndex(
        (explore) => explore.id === action.payload.id,
      );
      if (index !== undefined && index !== -1) {
        state.value.envExplores?.splice(index, 1, action.payload);
      }
    },
    removeEnvExplore: (state, action: PayloadAction<string>) => {
      state.value.envExplores = state.value.envExplores?.filter(
        (explore) => explore.id !== action.payload,
      );
    },
  },
});

export const {
  setEnvironment,
  setEnvironmentAPIKey,
  bumpWsListUpdateCounter,
  setEnvExplores,
  addEnvExplore,
  updateEnvExplore,
  removeEnvExplore,
} = environmentSlice.actions;

export default environmentSlice.reducer;
