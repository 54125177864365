import { useCallback } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { FieldAppearanceType } from '@madeinventive/core-types';

import Badge from './Badge';
import { AvailableColors } from '../shared/ColorSelector';
import ProgressBar from './ProgressBar';

const clapUnified = '1f44f';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    width: '70%',
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      marginLeft: theme.spacing(1),
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)({
  flexDirection: 'column',
});

interface AppearanceSelectorProps {
  appearanceType: FieldAppearanceType;
  setAppearanceType: (value: FieldAppearanceType) => void;
}

const AppearanceSelector = ({
  appearanceType,
  setAppearanceType,
}: AppearanceSelectorProps) => {
  const toggleAppearance = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: FieldAppearanceType) => {
      if (value !== null) {
        setAppearanceType(value);
      }
    },
    [setAppearanceType],
  );

  return (
    <StyledToggleButtonGroup
      value={appearanceType}
      color='neutral'
      exclusive
      fullWidth={true}
      onChange={toggleAppearance}
    >
      <StyledToggleButton value={FieldAppearanceType.PLAIN_TEXT}>
        <Typography variant='body1'>Complete</Typography>
        <Typography variant='body2'>Plain Text</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={FieldAppearanceType.BADGE}>
        <Box width='100%' mb={1}>
          <Badge
            text='Complete'
            emoji={clapUnified}
            color={AvailableColors.GREEN}
          />
        </Box>
        <Typography variant='body2'>Badge / Icon</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={FieldAppearanceType.PROGRESS_BAR}>
        <Box width='100%' mb={1}>
          <ProgressBar number={65.555} divideBy={100} showNumber={true} />
        </Box>
        <Typography variant='body2'>Progress Bar</Typography>
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default AppearanceSelector;
