import { createSlice } from '@reduxjs/toolkit';
import { MessageData } from '../../components/WorkspaceChat/types';
import { getUpdatedChatFeedMessagesByType } from '../../components/WorkspaceChat/utils/chatUtils';
import { AUTOMATIC_EXPLORE_VALUE } from '../../components/WorkspaceChat/constants';

export interface ChatState {
  // chat message
  chatFeedMessages: MessageData[];
  isThinking: boolean; // chat is sent and waiting for response
  isRunning: boolean; // chat is receiving responses
  lastMsgInView: boolean; // last message is in the chat thread viewport
  chatInput: string;
  // dataSource
  dataSourceId?: string;
  selectedCustomerFilterValues: string[];
  // thread
  isCurrentUserThreadOwner?: boolean;
  chatThreadId?: string;
}

export const initialChatStateValue: ChatState = {
  // chat message
  chatFeedMessages: [],
  isThinking: false,
  isRunning: false,
  lastMsgInView: false,
  chatInput: '',
  // dataSource
  dataSourceId: AUTOMATIC_EXPLORE_VALUE,
  selectedCustomerFilterValues: [],
  // thread
  isCurrentUserThreadOwner: true,
  chatThreadId: undefined,
};

export const CurrentChatSlice = createSlice({
  name: 'currentChat',
  initialState: { value: initialChatStateValue },
  reducers: {
    handleChatMessageData: (state, action) => {
      const { type, message, senderName, chatThreadId } = action.payload;
      const newChatFeedMessages = getUpdatedChatFeedMessagesByType({
        type,
        chatFeedMessages: state.value.chatFeedMessages,
        newMessage: message,
        senderName,
      });
      state.value.chatFeedMessages = newChatFeedMessages;
      state.value.chatThreadId = chatThreadId;
    },
    updateChatState: (state, action) => {
      const { chatState } = action.payload;
      state.value = { ...state.value, ...chatState };
    },
    setLastMsgInView: (state, action) => {
      state.value.lastMsgInView = action.payload;
    },
    setDataSourceId: (state, action) => {
      state.value.dataSourceId = action.payload;
    },
    setChatInput: (state, action) => {
      state.value.chatInput = action.payload;
    },
    setIsCurrentUserThreadOwner: (state, action) => {
      state.value.isCurrentUserThreadOwner = action.payload;
    },
    setSelectedCustomerFilterValues: (state, action) => {
      state.value.selectedCustomerFilterValues = action.payload;
    },
    resetCurrentChatState: (state) => {
      state.value = initialChatStateValue;
    },
    updateChatResponseRating: (state, action) => {
      const { chatFeedMessages } = state.value;
      const { chatResponseId, rating } = action.payload;
      const updatedChatFeedMessages = chatFeedMessages.map((msgData) => {
        if (
          typeof msgData.message !== 'string' &&
          msgData.message.id === chatResponseId
        ) {
          return {
            ...msgData,
            message: {
              ...msgData.message,
              rating,
            },
          };
        }
        return msgData;
      });
      state.value.chatFeedMessages = updatedChatFeedMessages;
    },
  },
});

export const {
  handleChatMessageData,
  updateChatState,
  setDataSourceId,
  setLastMsgInView,
  setChatInput,
  setIsCurrentUserThreadOwner,
  setSelectedCustomerFilterValues,
  resetCurrentChatState,
  updateChatResponseRating,
} = CurrentChatSlice.actions;

export default CurrentChatSlice.reducer;
