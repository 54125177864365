import Head from 'next/head';
import { useSessionInfo } from '../../hooks';

const Header = () => {
  const { environmentName, workspaceName, customBranding } = useSessionInfo();

  let title;
  if (environmentName) {
    title = `${environmentName} | Inventive`;
  } else if (workspaceName) {
    title = `${workspaceName} | Inventive`;
  } else {
    title = 'Inventive';
  }

  const favicon = customBranding?.iconUrl || '/favicon.ico';

  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={`Landing page for ${title}`} />
      <link rel='icon' href={favicon} />
    </Head>
  );
};

export default Header;
