import { Components } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';

export const components: Components = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '9px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: palette.text?.primary,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        minHeight: '40px',
        borderColor: palette.border.actionable,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        borderColor: palette.border.actionable,
      },
      notchedOutline: {
        borderColor: palette.border.actionable,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        a: {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: typography.h5?.fontSize,
        color: palette.text?.secondary,
        '&.Mui-selected': {
          color: palette.text?.primary,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTabs-indicator': {
          backgroundColor: palette.neutrals.black,
        },
      },
    },
  },
};
