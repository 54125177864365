import { useCallback, useState, useMemo } from 'react';
import { Stack } from '@mui/material';

// components
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import ToggleChips from '../shared/ToggleChips';
import DialogActions from '../shared/Dialog/DialogActions';

// hooks and context
import {
  useAppDispatch,
  useAppSelector,
  useFeatureFlags,
  FeatureEnum,
} from '../../hooks';
import {
  updateChatConfig,
  ChatConfig,
  GPT4_OMNI,
  GPT4_TURBO,
} from '../../store/slices/workspace';
import { setDataSourceId } from '../../store/slices/currentChat';

// utils
import { AUTOMATIC_EXPLORE_VALUE } from '../WorkspaceChat/constants';
import { BaseRegisteredDialogComponentProps } from './types';
import {
  chatSettingOptions,
  CHAT_SETTING_VALUES,
  getSelectedChatSetting,
} from '../../utils/chatSettings';

export interface ChatSettingsProps extends BaseRegisteredDialogComponentProps {}

const ChatSettings = ({ hideDialog }: BaseRegisteredDialogComponentProps) => {
  const storeDispatch = useAppDispatch();
  const { isFeatureEnabled } = useFeatureFlags();
  const { chatConfig } = useAppSelector((store) => store.workspace.value);
  const { dataSourceId } = useAppSelector((store) => store.currentChat.value);
  const { dataSourceOptions } = useAppSelector(
    (store) => store.workspace.value,
  );

  const [selectedDataSourceId, setSelectedDataSourceId] = useState<string>(
    dataSourceId ?? AUTOMATIC_EXPLORE_VALUE,
  );

  const initialSelectedSettings = useMemo(() => {
    if (isFeatureEnabled(FeatureEnum.ChatConfiguration)) {
      return getSelectedChatSetting(chatConfig);
    }
    return [];
  }, [chatConfig, isFeatureEnabled]);

  const [selectedSettings, setSelectedSettings] = useState<string[]>(
    initialSelectedSettings,
  );

  // handlers
  const handleDataSourceClick = useCallback((value: string) => {
    // update local state only
    setSelectedDataSourceId(value);
  }, []);

  const handleSettingItemClick = useCallback((value: string) => {
    // update local state only
    const key = chatSettingOptions.find(
      (option) => option.value === value,
    )?.configKey;
    if (key) {
      setSelectedSettings((prev) => {
        if (prev.includes(value)) {
          return prev.filter((v) => v !== value);
        }
        return [...prev, value];
      });
    }
  }, []);

  const handleSubmit = useCallback(() => {
    // update data source
    storeDispatch(setDataSourceId(selectedDataSourceId));

    // update internal settings
    if (isFeatureEnabled(FeatureEnum.ChatConfiguration)) {
      const newConfig: ChatConfig = {
        ...chatConfig,
        model: selectedSettings.includes(CHAT_SETTING_VALUES.GPT4_TURBO)
          ? GPT4_TURBO
          : GPT4_OMNI,
        enableQuickFollow: selectedSettings.includes(
          CHAT_SETTING_VALUES.QUICK_FOLLOW,
        ),
        enableAnswerabilityCheck: selectedSettings.includes(
          CHAT_SETTING_VALUES.ANSWERABILITY_CHECK,
        ),
        enableCommonQuestionHints: selectedSettings.includes(
          CHAT_SETTING_VALUES.COMMON_QUESTION_HINTS,
        ),
        enableDataScientist: selectedSettings.includes(
          CHAT_SETTING_VALUES.DATA_SCIENTIST_SUMMARY,
        ),
        enableFieldReduction: selectedSettings.includes(
          CHAT_SETTING_VALUES.FIELD_REDUCTION,
        ),
        enableDataTransformation: selectedSettings.includes(
          CHAT_SETTING_VALUES.DATA_TRANSFORMATION,
        ),
        enableEmbeddings: selectedSettings.includes(
          CHAT_SETTING_VALUES.DATA_EMBEDDINGS,
        ),
      };

      storeDispatch(updateChatConfig(newConfig));
    }
  }, [
    chatConfig,
    isFeatureEnabled,
    selectedDataSourceId,
    selectedSettings,
    storeDispatch,
  ]);

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <FormFieldLabel text='Data source' />
        <ToggleChips
          options={dataSourceOptions ?? []}
          selectedValues={
            selectedDataSourceId
              ? [selectedDataSourceId]
              : [AUTOMATIC_EXPLORE_VALUE]
          }
          handleItemClick={handleDataSourceClick}
          flexWrap
        />
      </Stack>
      {/* Internal settings */}
      {isFeatureEnabled(FeatureEnum.ChatConfiguration) && (
        <Stack spacing={1}>
          <FormFieldLabel text='Internal settings' />
          <ToggleChips
            options={chatSettingOptions}
            selectedValues={selectedSettings}
            handleItemClick={handleSettingItemClick}
            flexWrap
          />
        </Stack>
      )}
      <DialogActions
        closeDialog={hideDialog}
        secondaryAction={{
          text: 'Cancel',
          action: hideDialog,
          disabled: false,
        }}
        primaryAction={{
          text: 'Apply',
          action: handleSubmit,
          disabled: false,
        }}
      />
    </Stack>
  );
};

export default ChatSettings;
