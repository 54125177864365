import {
  useState,
  useMemo,
  useRef,
  useCallback,
  ElementType,
  forwardRef,
} from 'react';

// slate
import { Descendant, BaseEditor, Editor, Transforms } from 'slate';
import { Slate, ReactEditor } from 'slate-react';

// components
import {
  TextField,
  InputBaseComponentProps,
  Box,
  InputAdornment,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import SlateEditable, { SlateEditableProps } from './SlateEditable';

// types and utils
import {
  convertRawStringToSlateValue,
  convertSlateValueToRawString,
  createSlateEditor,
} from './utils';
import { useElementWidth } from '../../hooks/useElementWidth';
import { DEFAULT_SLATE_VALUE } from './types';
import DynamicFieldButton from './DynamicFieldButton';

export interface DynamicFieldInputProps {
  defaultValue: string;
  onChange: (value: string) => void;
  onValueEntered?: (value: string) => Promise<void>; // for MultiValueDynamicFieldInput
  disableDynamicField?: boolean;
  showDebugger?: boolean;
}

const DynamicFieldInput = ({
  defaultValue,
  onChange,
  onValueEntered,
  disableDynamicField,
  showDebugger,
}: DynamicFieldInputProps) => {
  const [containerRef, containerWidth] = useElementWidth();
  const [convertedString, setConvertedString] = useState<string>('');

  // state for DataFieldSelector
  const [isDFSOpen, setIsDFSOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const [slateValue, setSlateValue] = useState<Descendant[]>(
    defaultValue
      ? convertRawStringToSlateValue(defaultValue)
      : DEFAULT_SLATE_VALUE,
  );

  const editor = useMemo(() => {
    return createSlateEditor(true);
  }, []);

  const handleChange = useCallback(
    (slateValue: Descendant[]) => {
      setSlateValue(slateValue);
      const converted = convertSlateValueToRawString(slateValue);
      setConvertedString(converted);
      onChange(converted);
    },
    [onChange],
  );

  const clearSlateContent = useCallback(() => {
    if (editor) {
      // Get the entire document range
      const [start, end] = Editor.edges(editor, []);

      // Select all content in the editor
      Transforms.select(editor, { anchor: start, focus: end });

      // Delete the selected content
      Transforms.delete(editor);
    }
  }, [editor]);

  const handleKeyDown = useCallback(
    async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && onValueEntered) {
        await onValueEntered(convertedString);
        // clear the content after value is entered
        // invalid value will not be entered
        clearSlateContent();
      }
    },
    [clearSlateContent, convertedString, onValueEntered],
  );

  const slateInputProps = useMemo<SingleLineSlateInputProps>(
    () => ({
      editor,
      slateValue,
      handleChange,
      popperAnchorRef: anchorRef,
      popperWidth: containerWidth,
      externalIsPopperOpen: isDFSOpen,
      onPopperOpen: () => {
        setIsDFSOpen(true);
      },
      onPopperClose: () => {
        setIsDFSOpen(false);
      },
      onKeyDown: handleKeyDown,
      disableDynamicField,
    }),
    [
      editor,
      slateValue,
      handleChange,
      containerWidth,
      isDFSOpen,
      handleKeyDown,
      disableDynamicField,
    ],
  );

  return (
    <Box ref={containerRef}>
      <TextField
        ref={anchorRef}
        InputProps={{
          inputComponent:
            SingleLineSlateInput as ElementType<InputBaseComponentProps>,
          inputProps: slateInputProps,
          endAdornment: !disableDynamicField && (
            <InputAdornment position='end'>
              <DynamicFieldButton
                onClick={() => setIsDFSOpen((prev) => !prev)}
                isOpen={isDFSOpen}
              />
            </InputAdornment>
          ),
        }}
        size='small'
        fullWidth
      />
      {/* Use this for debugging */}
      {showDebugger && (
        <Stack p={2} spacing={2} bgcolor='background.default'>
          <Typography variant='h5'>** Debugger **</Typography>
          <Typography variant='h6'>String Value</Typography>
          <Typography
            variant='body2'
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            {convertedString}
          </Typography>
          <Divider />
          <Typography variant='h6'>Slate Value</Typography>
          <Typography
            variant='body2'
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <code>{JSON.stringify(slateValue, null, 2)}</code>
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default DynamicFieldInput;

type SlateInputBaseProps = InputBaseComponentProps & SlateEditableProps;

interface SingleLineSlateInputProps extends SlateInputBaseProps {
  slateEditorResetKey?: number;
  slateValue: Descendant[];
  handleChange: (value: Descendant[]) => void;
  editor: BaseEditor & ReactEditor;
  disableDynamicField?: boolean;
}

const SingleLineSlateInput = forwardRef(
  (
    {
      slateEditorResetKey,
      slateValue,
      handleChange,
      editor,
      disableDynamicField,
      ...rest
    }: SingleLineSlateInputProps,
    ref,
  ) => {
    return (
      <Slate
        editor={editor}
        initialValue={slateValue}
        onChange={handleChange}
        key={slateEditorResetKey}
      >
        <SlateEditable
          ref={ref}
          isSingleLine={true}
          {...rest}
          disableDynamicField={disableDynamicField}
        />
      </Slate>
    );
  },
);

SingleLineSlateInput.displayName = 'SingleLineSlateInput';
