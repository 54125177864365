import { ViewerQuery } from '../generated/types';

const TOKEN_KEY = 'authTokens';
const USER_DETAILS_KEY = 'userDetails';
const OP_LOGGING_KEY = 'operationLogging';
const UI_STATE_KEY = 'uiState';

const INSTANCE_SUFFIX = (isEmbedded: boolean) => (isEmbedded ? '_2' : '_1');
const isEmbedded = () => window !== window.parent;
const tokenKey = () => TOKEN_KEY + INSTANCE_SUFFIX(isEmbedded());
const userDetailsKey = () => USER_DETAILS_KEY + INSTANCE_SUFFIX(isEmbedded());

export enum TokenState {
  INVALID = 'INVALID',
  VALID = 'VALID',
  NEED_REFRESH = 'NEED_REFRESH',
}

export interface Tokens {
  accessToken: string;
  accessTokenExpiresAt: Date;
  refreshToken: string;
  refreshTokenExpiresAt: Date;
}

const parseValidItem = (item: string | null) => {
  if (!item) return null;
  // local storage only stores strings
  // so if we get a stringified undefined, it's not valid either
  if (item === 'undefined') return null;
  return JSON.parse(item);
};

export interface UserDataEnviroment {
  __typename: string;
  id: string;
  name: string;
}

export const getTokens = (): Tokens => {
  const item = localStorage.getItem(tokenKey());
  return parseValidItem(item);
};

export const setTokens = (tokens: Tokens) => {
  localStorage.setItem(tokenKey(), JSON.stringify(tokens));
};

export const deleteTokens = () => {
  localStorage.removeItem(tokenKey());
};

export const getCurrentUserDetails = (): ViewerQuery['viewer']['user'] => {
  const item = localStorage.getItem(userDetailsKey());
  return parseValidItem(item);
};

export const setCurrentUserDetails = (
  details: ViewerQuery['viewer']['user'],
) => {
  localStorage.setItem(userDetailsKey(), JSON.stringify(details));
};

export const deleteCurrentUserDetails = () => {
  localStorage.removeItem(userDetailsKey());
};

export const getSystemUserSelectedEnvironmentId = () => {
  return localStorage.getItem('systemSelectedEnvironmentId');
};

export const setSystemUserSelectedEnvironmentId = (environmentId: string) => {
  localStorage.setItem('systemSelectedEnvironmentId', environmentId);
};

export const deleteSystemUserSelectedEnvironmentId = () => {
  localStorage.removeItem('systemSelectedEnvironmentId');
};

export const getTokenState = () => {
  const tokens = getTokens();
  if (!tokens) return TokenState.INVALID;

  const currentTime = new Date();
  // both tokens valid
  if (
    tokens.accessTokenExpiresAt &&
    currentTime < new Date(tokens.accessTokenExpiresAt)
  )
    return TokenState.VALID;
  // access token expired but refresh token remains valid
  if (
    tokens.refreshTokenExpiresAt &&
    currentTime < new Date(tokens.refreshTokenExpiresAt)
  )
    return TokenState.NEED_REFRESH;
  // both tokens expired
  return TokenState.INVALID;
};

export const getOperationLogging = (): boolean => {
  const item = localStorage.getItem(OP_LOGGING_KEY);
  return item ? JSON.parse(item) : false;
};

export const setOperationLogging = (toLog: boolean) => {
  localStorage.setItem(OP_LOGGING_KEY, JSON.stringify(toLog));
};

export const deleteOperationLogging = () => {
  localStorage.removeItem(OP_LOGGING_KEY);
};

export const setUIState = (key: string, value: string) => {
  localStorage.setItem(`${UI_STATE_KEY}_${key}`, value);
};

export const getUIState = (key: string) => {
  return localStorage?.getItem(`${UI_STATE_KEY}_${key}`);
};
