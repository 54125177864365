import React, { useState } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from './DialogActions';
import {
  DialogActionType,
  BaseDialogActionType,
} from '../../../hooks/useDialog';
import {
  DIALOG_IDS,
  DIALOG_REGISTRY,
  DialogComponentPropsMapping,
} from '../../registeredDialogs/dialogRegistry';

interface DialogProps<ID extends DIALOG_IDS> {
  id: ID;
  open: boolean;
  hideClose?: boolean;
  title: string;
  contentProps: DialogComponentPropsMapping[ID];
  primaryAction?: DialogActionType;
  secondaryAction?: BaseDialogActionType;
  children?: React.ReactNode;
}

const Dialog = <ID extends DIALOG_IDS>({
  id,
  open,
  hideClose = false,
  title,
  contentProps,
  primaryAction,
  secondaryAction,
  children,
}: DialogProps<ID>) => {
  const [result, setResult] = useState<boolean | string[]>(false);
  const success = typeof result === 'boolean' && result;
  const errors = typeof result !== 'boolean' && result;
  const hasErrors = !!errors && errors.length > 0;
  const { hideDialog } = contentProps;

  // This needs type assertion because id extends DIALOG_IDs and
  // TypeScript infers the intersection of all possible types for the component
  // ContentComponent props end up being intersection of all possible props instead of union.
  const ContentComponent = DIALOG_REGISTRY[id]?.Component as React.FC<
    DialogComponentPropsMapping[ID]
  >;

  const showSuccessMessage =
    success && primaryAction?.asyncAction && primaryAction.successMessage;
  const showErrorMessage =
    !success && primaryAction?.asyncAction && primaryAction.errorMessage;

  return (
    <MuiDialog
      open={open}
      onClose={hideDialog}
      aria-labelledby='modal-title'
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle
        id='dialog-title'
        variant='h4'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title}
        {!hideClose && hideDialog ? (
          <IconButton
            aria-label='close'
            onClick={hideDialog}
            sx={{
              marginRight: (theme) => `-${theme.spacing(2)}`,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {hasErrors ? (
          <>
            {showErrorMessage && (
              <Typography variant='h4' color='error'>
                {primaryAction.errorMessage}
              </Typography>
            )}
            <ul>
              {errors.map((error) => (
                <li key={error}>
                  <Typography variant='body1'>{error}</Typography>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            {!showSuccessMessage && children && <>{children}</>}
            {!showSuccessMessage && ContentComponent && (
              <ContentComponent {...contentProps} />
            )}
            {showSuccessMessage && (
              <Typography variant='h4' color='text.secondary'>
                {primaryAction.successMessage}
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      {secondaryAction || primaryAction ? (
        <DialogActions
          isBuiltIn={true}
          closeDialog={hideDialog}
          secondaryAction={secondaryAction}
          primaryAction={primaryAction}
          handleResult={setResult}
        />
      ) : null}
    </MuiDialog>
  );
};

export default Dialog;
