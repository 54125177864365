import LogRocket from 'logrocket';
import {
  ConfigureStoreOptions,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import exploreExtractsReducer from './slices/exploreExtracts';
import errorReducer from './slices/error';
import loadedWorkspaceExploresReducer from './slices/loadedWorkspaceExplores';
import modalReducer from './slices/modal';
import sessionReducer from './slices/session';
import featureEditDataReducer from './slices/features/featureEditData';
import environmentReducer from './slices/environment';
import workspaceReducer from './slices/workspace';
import chatThreadsReducer from './slices/chatThreads';
import environmentMembersReducer from './slices/environmentMembers';
import workspaceComponentReducer from './slices/workspaceComponent';
import workspaceComponentsReducer from './slices/workspaceComponents';
import currentChatReducer from './slices/currentChat';
import loadedChatsReducer from './slices/loadedChats';
import uiControlsReducer from './slices/uiControls';
import userNotificationPreferencesReducer from './slices/userNotificationPreferences';

export const ResetStoreAction = { type: 'store/reset' };

export const rootReducer = combineReducers({
  error: errorReducer,
  exploreExtracts: exploreExtractsReducer,
  featureEditData: featureEditDataReducer,
  loadedExplores: loadedWorkspaceExploresReducer,
  modal: modalReducer,
  session: sessionReducer,
  environment: environmentReducer,
  workspace: workspaceReducer,
  environmentMembers: environmentMembersReducer,
  workspaceComponent: workspaceComponentReducer,
  workspaceComponents: workspaceComponentsReducer,
  chatThreads: chatThreadsReducer,
  currentChat: currentChatReducer,
  loadedChats: loadedChatsReducer,
  uiControls: uiControlsReducer,
  userNotificationPreferences: userNotificationPreferencesReducer,
});

const resettableRootReducer: typeof rootReducer = (state, action) => {
  return rootReducer(
    action.type === ResetStoreAction.type ? undefined : state,
    action,
  );
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(LogRocket.reduxMiddleware()),
});

// The function below is used to create a store for unit tests
export const storeFactory = (options?: Partial<ConfigureStoreOptions>) =>
  configureStore({
    reducer: resettableRootReducer,
    ...options,
  });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
