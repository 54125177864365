import { Ref, forwardRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import selection from '../../../public/fontIcons/selection.json';
export const iconNames = selection.icons.map((icon) => icon.properties.name);

export const isIconName = (name: string): name is IconName =>
  iconNames.includes(name);

import { IconName } from './types';

const sizeMap = {
  small: 16,
  large: 20,
  xlarge: 24,
};

interface IconPops extends TypographyProps {
  name: IconName;
  size?: keyof typeof sizeMap | number;
  color?: TypographyProps['color'];
  className?: string;
}

const Icon = forwardRef(
  (
    { name, size = 'large', color, className, ...rest }: IconPops,
    ref: Ref<HTMLSpanElement>,
  ) => {
    return (
      <Typography
        component='span'
        ref={ref}
        tabIndex={0}
        className={`icon-${name} ${className}`}
        sx={{
          lineHeight: 1,
          letterSpacing: 'unset',
          fontSize: typeof size === 'string' ? sizeMap[size] : size,
        }}
        color={color}
        {...rest}
      />
    );
  },
);

Icon.displayName = 'Icon';

export default Icon;
