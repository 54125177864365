import { useState, useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

// types
import {
  ChatRequestMessage,
  ChatRole,
  SortOrder,
  useRateChatResponseMutation,
  useStopChatResponseMutation,
  useStartChatResponseMutation,
  useWorkspaceChatResponseLazyQuery,
  useWorkspaceChatResponsesLazyQuery,
  ChatResponse,
  ChatResponseRating,
  StartChatResponseResult,
  ResponseUpdatedDocument,
  ResponseUpdatedSubscription,
  ResponseUpdatedSubscriptionVariables,
} from '../generated/types';
import { PickedChatResponse } from '../components/WorkspaceChat/types';
import {
  IncrementalResponseUpdateMsg,
  EndOfResponseUpdateMsg,
  Routes,
  ThoughtClassification,
  ThoughtV2,
  isValid,
  validators,
  DebugThought,
} from '@madeinventive/core-types';

// hooks
import {
  useAppDispatch,
  useSessionInfo,
  useAppSelector,
  useChatThreads,
  useToast,
} from '.';

// actions
import {
  ChatState,
  handleChatMessageData,
  updateChatState,
  updateChatResponseRating,
  setChatInput,
  setLastMsgInView,
} from '../store/slices/currentChat';

import {
  handleChatMessageDataByThreadId,
  updateLoadedChatByThreadId,
} from '../store/slices/loadedChats';

import {
  addChatThread,
  addInProgressChatThreadById,
  completeInProgressChatThreadById,
  removeInProgressChatThreadById,
} from '../store/slices/chatThreads';

import {
  ChatFeedMessageType,
  convertChatResponsesToChatFeeds,
  convertChatResponsesToOpenAiMessages,
} from '../components/WorkspaceChat/utils/chatUtils';
import { AUTOMATIC_EXPLORE_VALUE } from '../components/WorkspaceChat/constants';
import { useEmbeddingContext } from '../contexts/EmbeddingProvider';

const POLLING_INTERVAL = 3000;

// patch missing leading thoughts in the chain with temporary placeholder debug thoughts (not visible to user)
const patchMissing = (
  thoughts: ThoughtV2[],
  firstPendingUpdate: IncrementalResponseUpdateMsg,
) => {
  if (!thoughts.length && firstPendingUpdate.thoughtIndex >= 0) {
    const nbrOfMissing = firstPendingUpdate.thoughtIndex + 1;
    const firstThoughtTime = new Date(
      firstPendingUpdate.newThought.updatedAt,
    ).getTime();
    for (let i = 0; i < nbrOfMissing; i++) {
      const placeholderMsg: DebugThought = {
        classification: ThoughtClassification.Debug,
        // create placeholder thoughts that's `i` milliseconds before the first thought
        updatedAt: new Date(
          firstThoughtTime - i,
        ).toISOString() as unknown as Date,
        module: 'chat',
        message: '...',
      };
      thoughts.unshift(placeholderMsg);
    }
  }
};

export interface RatingReasons {
  tags?: string;
  summary?: string;
  comment?: string;
}

export const useChat = (workspaceId: string) => {
  const router = useRouter();
  const storeDispatch = useAppDispatch();
  const { fetchSelectedChatThread, setSelectedChatThreadId } =
    useChatThreads(workspaceId);

  const { isEmbedded, hostContext } = useEmbeddingContext();

  // values from redux
  const { selectedChatThreadId, inProgressChatThreads } = useAppSelector(
    (store) => store.chatThreads.value,
  );
  const { isRunning, dataSourceId, selectedCustomerFilterValues } =
    useAppSelector((store) => store.currentChat.value);
  const currentUserId = useAppSelector((store) => store.session.value.id);
  const { chatConfig } = useAppSelector((store) => store.workspace.value);
  const { loadedChats } = useAppSelector((store) => store.loadedChats.value);
  const { firstName } = useSessionInfo();
  const inProgressChatThreadsRef = useRef(
    useAppSelector((store) => store.chatThreads.value).inProgressChatThreads,
  );
  const loadedChatsRef = useRef(
    useAppSelector((store) => store.loadedChats.value).loadedChats,
  );

  // local states
  const [isLoading, setIsLoading] = useState(false);
  const currentChatThreadIdFromUrl = useRef<string | null>(null); // url change shouldn't trigger initializeChat
  const currentChatResponseId = useRef<string | null>(null);
  const initializedChatThreadIdRef = useRef<string | null>(null);
  const isChatInSavedVizRef = useRef<boolean>(false);
  const [stoppedMessage, setStoppedMessage] = useState<string | null>(null);
  // this message list is used to send to the backend
  const [openAIChatMsgs, setOpenAIChatMsgs] = useState<ChatRequestMessage[]>(
    [],
  );
  const [pollFallback, setPollFallback] = useState(false);

  const unsubscribeUpdatesRef = useRef<(() => void) | null>(null);

  // queries
  const [fetchChatResponses] = useWorkspaceChatResponsesLazyQuery();
  const [
    fetchChatResponse,
    {
      data: chatResponseData,
      refetch: refetchChatResponse,
      error: responseError,
      subscribeToMore,
    },
  ] = useWorkspaceChatResponseLazyQuery();
  const [stopChatResponse] = useStopChatResponseMutation();
  const [startChatResponse, { error: startChatError }] =
    useStartChatResponseMutation();

  const [setChatResponseRating] = useRateChatResponseMutation();

  const { showErrorToast } = useToast();

  // set chat thread id from url
  // this is used to initialize chat when user tries to load a chat thread from the url (link)
  useEffect(() => {
    const chatThreadIdFromUrl = router.query.chatThreadId as string;
    const componentIdFromUrl = router.query.componentId as string;
    currentChatThreadIdFromUrl.current = chatThreadIdFromUrl || null;
    isChatInSavedVizRef.current = !!componentIdFromUrl;
  }, [router]);

  // set in-progress chat ref when it changes
  // useChat will use ref only to avoid re-initializing the chat
  // when a new prompt is started and updating the inProgressChatThreads in the store
  useEffect(() => {
    inProgressChatThreadsRef.current = inProgressChatThreads;
  }, [inProgressChatThreads]);

  useEffect(() => {
    loadedChatsRef.current = loadedChats;
  }, [loadedChats]);

  const rateChatResponse = useCallback(
    async (
      chatResponseId: string,
      newRating: ChatResponseRating | null,
      reasons?: RatingReasons,
      onFinish?: () => Promise<void>,
    ) => {
      setChatResponseRating({
        variables: {
          input: {
            id: chatResponseId,
            rating: newRating,
            ratingTags: reasons?.tags ?? null,
            ratingSummary: reasons?.summary ?? null,
            ratingComment: reasons?.comment ?? null,
          },
        },
      })
        .then(() => {
          storeDispatch(
            updateChatResponseRating({
              chatResponseId,
              rating: newRating,
            }),
          );
          if (onFinish) onFinish();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [setChatResponseRating, storeDispatch],
  );

  const updateCurrentAndLoadedChatState = useCallback(
    (
      chatState: Partial<ChatState>,
      chatThreadId?: string | null,
      trigger?: string,
    ) => {
      storeDispatch(updateChatState({ chatState, trigger }));
      storeDispatch(
        updateLoadedChatByThreadId({
          chatThreadId: chatThreadId ?? 'new',
          chatState,
          trigger,
        }),
      );
    },
    [storeDispatch],
  );

  const handleChatMessageForCurrentAndLoadedChat = useCallback(
    ({
      type,
      message,
      trigger,
      chatThreadId,
      senderName,
      dataSourceId,
    }: {
      type: ChatFeedMessageType;
      message: string | PickedChatResponse;
      trigger?: string;
      chatThreadId?: string | null;
      senderName?: string;
      dataSourceId?: string;
    }) => {
      storeDispatch(
        handleChatMessageData({
          type,
          chatThreadId,
          message,
          senderName,
          dataSourceId,
          trigger,
        }),
      );

      storeDispatch(
        handleChatMessageDataByThreadId({
          type,
          chatThreadId: chatThreadId ?? 'new',
          message,
          senderName,
          dataSourceId,
          trigger,
        }),
      );
    },
    [storeDispatch],
  );

  // This function is called when the chat response is received
  // from these functions: fetchChatResponse, refetchChatResponse, startChatResponse, stopChatResponse
  // this function will update the chat feed and the chat state with the response
  const handleChatResponse = useCallback(
    (chatResponse: ChatResponse) => {
      // when the chat is completed or canceled, stop running state
      // and remove the chat thread from inProgressChatThreads
      if (chatResponse.completed || chatResponse.canceled) {
        updateCurrentAndLoadedChatState(
          {
            isRunning: false,
            dataSourceId: AUTOMATIC_EXPLORE_VALUE,
            chatThreadId: chatResponse.chatThreadId,
          },
          chatResponse.chatThreadId,
          'chatResponseCompleted',
        );
        storeDispatch(
          removeInProgressChatThreadById(initializedChatThreadIdRef.current),
        );
      }

      handleChatMessageForCurrentAndLoadedChat({
        type: ChatFeedMessageType.RESPONSE,
        message: chatResponse,
        trigger: 'chatResponseUpdated',
        chatThreadId: chatResponse.chatThreadId,
      });
    },
    [
      handleChatMessageForCurrentAndLoadedChat,
      storeDispatch,
      updateCurrentAndLoadedChatState,
    ],
  );

  const isStreamingUpdates = useCallback(
    () => !!unsubscribeUpdatesRef.current,
    [],
  );
  const cancelStreamingUpdates = useCallback(() => {
    if (unsubscribeUpdatesRef.current) {
      unsubscribeUpdatesRef.current();
      unsubscribeUpdatesRef.current = null;
    }
  }, []);

  const subscribeToResponseUpdates = useCallback(
    (chatResponseId: string, onError?: (error: unknown) => void) => {
      let pendingMsgs: IncrementalResponseUpdateMsg[] = [];
      const unsubscribeFunction = subscribeToMore<
        ResponseUpdatedSubscription,
        ResponseUpdatedSubscriptionVariables
      >({
        document: ResponseUpdatedDocument,
        variables: { chatResponseId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data.responseUpdated) return prev;

          const { responseUpdated } = subscriptionData.data;

          if (prev.node?.__typename !== 'Workspace') {
            return prev;
          }

          if (
            isValid<EndOfResponseUpdateMsg>(
              validators.EndOfResponseUpdateMsg,
              responseUpdated,
            )
          ) {
            // clean up the subscription to response updates
            cancelStreamingUpdates();
            // initiate final fetch of the full response record
            // as part of the transition back into idle mode
            refetchChatResponse()
              .then((res) => {
                if (res.data.node?.__typename === 'Workspace') {
                  // put behind type guard to keep TS happy
                  handleChatResponse(
                    res.data.node.chatResponse as ChatResponse,
                  );
                }
              })
              .catch((e) => console.error(e));
            return prev;
          }

          if (
            !isValid<IncrementalResponseUpdateMsg>(
              validators.IncrementalResponseUpdateMsg,
              responseUpdated,
            )
          ) {
            return prev;
          }

          pendingMsgs.push(responseUpdated);

          // no chat response yet (starting new) or the incoming update is for a different chat response (resuming)
          // process the message later
          if (
            !prev.node.chatResponse ||
            prev.node.chatResponse?.id !== responseUpdated.chatResponseId
          ) {
            return prev;
          }

          const mergedThoughts: ThoughtV2[] = [
            ...(prev.node.chatResponse.classifiedThoughts ?? []),
          ];

          // process all messages in sequence
          patchMissing(mergedThoughts, pendingMsgs[0]);
          pendingMsgs.forEach((msg) => {
            if (
              msg.thoughtIndex >= 0 &&
              msg.thoughtIndex < mergedThoughts.length
            )
              mergedThoughts[msg.thoughtIndex] = msg.newThought;
            else mergedThoughts.push(msg.newThought);
          });
          pendingMsgs = [];

          const updated = Object.assign({}, prev, {
            node: {
              ...prev.node,
              chatResponse: {
                ...prev.node.chatResponse,
                classifiedThoughts: mergedThoughts,
              },
            },
          });
          return updated;
        },
        onError: (err) => {
          console.error(
            `Error subscribing to updates for chat response '${chatResponseId}':`,
            err,
          );
          if (onError) onError(err);
        },
      });
      return unsubscribeFunction;
    },
    [
      subscribeToMore,
      cancelStreamingUpdates,
      refetchChatResponse,
      handleChatResponse,
    ],
  );

  const resumeStreaming = useCallback(
    async (threadId: string, chatResponseId: string) => {
      updateCurrentAndLoadedChatState(
        {
          isRunning: true,
        },
        threadId,
        'resumeStreaming',
      );

      // re-subscribe to response updates
      unsubscribeUpdatesRef.current = subscribeToResponseUpdates(
        chatResponseId,
        () => {
          cancelStreamingUpdates();
          setPollFallback(true);
        },
      );

      fetchChatResponse({
        variables: {
          workspaceId,
          chatMessageId: chatResponseId,
        },
      }).catch((e) => {
        console.error('Error fetching response', e);
      });
    },
    [
      fetchChatResponse,
      updateCurrentAndLoadedChatState,
      subscribeToResponseUpdates,
      cancelStreamingUpdates,
      workspaceId,
    ],
  );

  const checkInProgressChatThreads = useCallback(
    (threadId: string) => {
      // check if the thread is in progress
      const inProgressThread = inProgressChatThreadsRef.current.find(
        (thread) => thread.id === threadId,
      );

      if (inProgressThread) {
        resumeStreaming(threadId, inProgressThread.responseId);
      }
    },
    [resumeStreaming],
  );

  const loadThreadResponses = useCallback(
    async (threadId: string) => {
      setIsLoading(true);
      const responses = await fetchChatResponses({
        variables: {
          workspaceId,
          params: {
            chatThreadId: threadId, // must use chat thread id from url
            sortOrder: SortOrder.ASCENDING,
          },
        },
      });
      setIsLoading(false);

      if (responses) {
        const chatResponses =
          responses.data?.node?.__typename === 'Workspace'
            ? responses.data.node.chatResponses.edges.map((edge) => edge.node)
            : [];

        const chatFeeds = convertChatResponsesToChatFeeds(chatResponses);
        updateCurrentAndLoadedChatState(
          {
            chatFeedMessages: chatFeeds,
            dataSourceId:
              chatResponses[chatResponses.length - 1]?.dataSourceId ??
              AUTOMATIC_EXPLORE_VALUE,
          },
          threadId,
          'loadThreadResponses',
        );

        const openAiMessages =
          convertChatResponsesToOpenAiMessages(chatResponses);
        setOpenAIChatMsgs(openAiMessages);

        checkInProgressChatThreads(threadId);
      }
    },
    [
      checkInProgressChatThreads,
      fetchChatResponses,
      updateCurrentAndLoadedChatState,
      workspaceId,
    ],
  );

  const loadThread = useCallback(
    async (threadId: string) => {
      const loadedChatState = loadedChatsRef.current[threadId];
      if (loadedChatState) {
        // this will load all the chat response feed from the store
        storeDispatch(
          updateChatState({
            chatState: loadedChatState,
            trigger: 'loadThread',
          }),
        );
        checkInProgressChatThreads(threadId);
      } else {
        setIsLoading(true);
        const chatThreadToInitialize = await fetchSelectedChatThread(threadId);
        if (chatThreadToInitialize) {
          initializedChatThreadIdRef.current = chatThreadToInitialize.id;
          updateCurrentAndLoadedChatState(
            {
              isCurrentUserThreadOwner:
                chatThreadToInitialize.userId === currentUserId,
            },
            chatThreadToInitialize.id,
            'loadThread',
          );
          loadThreadResponses(threadId);
        }
      }
    },
    [
      storeDispatch,
      checkInProgressChatThreads,
      currentUserId,
      fetchSelectedChatThread,
      loadThreadResponses,
      updateCurrentAndLoadedChatState,
    ],
  );

  // When user clicks the new chat button,
  // reset the chat memory and start a new chat
  const resetChatMemory = useCallback(() => {
    initializedChatThreadIdRef.current = null;
    currentChatThreadIdFromUrl.current = null;
    setOpenAIChatMsgs([]);
    storeDispatch(
      updateChatState({
        chatState: {
          isRunning: false,
          isThinking: false,
          lastMsgInView: false,
          chatFeedMessages: [],
          isCurrentUserThreadOwner: true,
          dataSourceId: AUTOMATIC_EXPLORE_VALUE,
        },
        trigger: 'resetChatMemory',
      }),
    );
    setSelectedChatThreadId(undefined);
  }, [storeDispatch, setSelectedChatThreadId]);

  const initializeChat = useCallback(async () => {
    // for any chat initialization, stop outstanding subscription, if any
    cancelStreamingUpdates();

    if (!selectedChatThreadId) {
      if (!currentChatThreadIdFromUrl.current) {
        // new chat
        resetChatMemory();
      } else {
        // load chat from url
        // make sure to set the initializedChatThreadIdRef.current
        // so that the chat is not re-initialized when a new prompt is started
        // See handleStartChatResponse's route.push call
        initializedChatThreadIdRef.current = currentChatThreadIdFromUrl.current;
        await loadThread(currentChatThreadIdFromUrl.current);
      }
    } else {
      // TODO: Clicking the previous chat thread does not trigger this
      // It triggers `load chat from url` instead. Clean up the code.

      // click previous chat
      initializedChatThreadIdRef.current = selectedChatThreadId;
      await loadThread(selectedChatThreadId);
    }
  }, [
    selectedChatThreadId,
    resetChatMemory,
    loadThread,
    cancelStreamingUpdates,
  ]);

  useEffect(() => {
    initializeChat();
  }, [initializeChat, router.query.chatThreadId]);

  // error handling
  // this handles API errors that cannot be handled by BE, such as the errors that occurs when creating or updating ChatResponse.
  // BE usually completes the response and adds the error to the thoughts.
  useEffect(() => {
    const error = startChatError ?? responseError;
    if (error) {
      updateCurrentAndLoadedChatState(
        {
          isRunning: false,
        },
        initializedChatThreadIdRef.current,
        'errorHandling',
      );

      if (error instanceof Error) {
        console.error(`${error.name}: ${error.message}`);
      }

      let errorMessageText = `An error occurred. Please try again later.`;

      if (
        error instanceof Error &&
        error.name === 'ApolloError' &&
        error.message === 'Failed to fetch'
      ) {
        errorMessageText = `An intermittent error occurred. Please refresh your browser. If this issue persists, try again later.`;
        showErrorToast('Something went wrong', 'Refresh', () =>
          window.location.reload(),
        );
      }

      handleChatMessageForCurrentAndLoadedChat({
        type: ChatFeedMessageType.ERROR,
        message: errorMessageText,
        trigger: 'errorHandling',
        chatThreadId: initializedChatThreadIdRef.current,
      });
    }
  }, [
    startChatError,
    responseError,
    storeDispatch,
    updateCurrentAndLoadedChatState,
    handleChatMessageForCurrentAndLoadedChat,
    showErrorToast,
  ]);

  // This useEffect will be triggered when a chatResponseData is updated
  // from fetchChatResponse or refetchChatResponse
  useEffect(() => {
    if (
      chatResponseData?.node?.__typename === 'Workspace' &&
      (isStreamingUpdates() || pollFallback)
    ) {
      const chatResponse = chatResponseData.node.chatResponse as ChatResponse;
      handleChatResponse(chatResponse);
    }
  }, [chatResponseData, handleChatResponse, isStreamingUpdates, pollFallback]);

  const handleStartChatResponse = useCallback(
    (result: StartChatResponseResult, userInput: string) => {
      const { chatThreadId, id: chatResponseId } = result.chatResponse;
      if (chatThreadId) {
        // add new thread to previous chats
        if (initializedChatThreadIdRef.current !== chatThreadId) {
          storeDispatch(
            addChatThread({
              id: chatThreadId,
              title: userInput,
              updatedAt: new Date().toISOString(),
              dataSourceId,
              dataSourceType: 'LOOKER',
              selectedCustomerFilterValues,
              user: {
                id: currentUserId,
              },
            }),
          );

          // TODO: now we don't support go back to the previous page, rewrite the router push logic below.
          // The only case we need to push new url to the browser history is when the chat is a new chat.

          // if chat is saved viz, chat feed will be loaded in the same page
          // so no need to push new url to the browser history
          if (!isChatInSavedVizRef.current) {
            // push new url to the browser history
            // to allow user to go back to the previous page
            router.push(Routes.chat(workspaceId, chatThreadId));
          }
        }
        initializedChatThreadIdRef.current = chatThreadId;
      }
      if (chatResponseId) {
        // subscribe to response updates
        unsubscribeUpdatesRef.current = subscribeToResponseUpdates(
          chatResponseId,
          () => {
            cancelStreamingUpdates();
            setPollFallback(true);
          },
        );

        currentChatResponseId.current = chatResponseId;
        storeDispatch(
          addInProgressChatThreadById({
            chatThreadId: chatThreadId,
            chatResponseId: chatResponseId,
            completed: false,
          }),
        );
        // Must start polling when the chat is started
        // before the first response is available.
        // The first response will check if the isRunning is true
        // to update the UI state.
        updateCurrentAndLoadedChatState(
          {
            isRunning: true,
            isThinking: false,
          },
          chatThreadId,
          'handleStartChatResponse',
        );
        // fetch chat response when the chat is started
        fetchChatResponse({
          variables: {
            workspaceId,
            chatMessageId: chatResponseId,
          },
        }).catch((e) => {
          console.error('Error fetching response', e);
        });
      }
    },
    [
      currentUserId,
      dataSourceId,
      fetchChatResponse,
      router,
      selectedCustomerFilterValues,
      storeDispatch,
      updateCurrentAndLoadedChatState,
      workspaceId,
      subscribeToResponseUpdates,
      cancelStreamingUpdates,
    ],
  );

  useEffect(() => {
    // cancel any subscription to response updates
    return () => {
      cancelStreamingUpdates();
    };
  }, [cancelStreamingUpdates]);

  useEffect(() => {
    // this is intentional - every time `isRunning` changes
    // we want to reset the poll fallback
    setPollFallback(false);
  }, [isRunning]);

  useEffect(() => {
    if (pollFallback) {
      // use polling as fallback when streaming updates are not available
      const interval = setInterval(() => {
        refetchChatResponse().catch((e) => {
          console.error(e);
        });
      }, POLLING_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [refetchChatResponse, pollFallback]);

  const componentId = (router.query.componentId as string) ?? undefined;

  const sendUserMessageToBackend = useCallback(
    async (
      dataSourceId: string,
      messagesToSend: ChatRequestMessage[],
      userInput: string,
    ) => {
      return startChatResponse({
        variables: {
          input: {
            workspaceId,
            ...(initializedChatThreadIdRef.current && {
              chatThreadId: initializedChatThreadIdRef.current,
            }),
            componentId: componentId,
            dataSourceId:
              dataSourceId === AUTOMATIC_EXPLORE_VALUE ? null : dataSourceId,
            selectedCustomerFilterValues,
            messages: messagesToSend,
            temperature: chatConfig.temperature,
            model: chatConfig.model,
            processingOptions: {
              enableQuickFollow: chatConfig.enableQuickFollow,
              enableAnswerabilityCheck: chatConfig.enableAnswerabilityCheck,
              enableCommonQuestionHints: chatConfig.enableCommonQuestionHints,
              enableDataScientist: chatConfig.enableDataScientist,
              enableFieldReduction: chatConfig.enableFieldReduction,
              enableDataTransformation: chatConfig.enableDataTransformation,
              enableEmbeddings: chatConfig.enableEmbeddings,
              vizGenerationMode: chatConfig.vizGenerationMode,
            },
            embedHostContext: isEmbedded ? hostContext : undefined,
          },
        },
      })
        .then((response) => {
          const result = response.data
            ?.startChatResponse as StartChatResponseResult;
          if (result) {
            handleStartChatResponse(result, userInput);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [
      chatConfig,
      handleStartChatResponse,
      hostContext,
      isEmbedded,
      selectedCustomerFilterValues,
      startChatResponse,
      workspaceId,
      componentId,
    ],
  );

  //// functions to expose
  // takes user input or message from the system and send to the backend
  const handleUserMessage = useCallback(
    async (userInput: string, isQuickFollow?: boolean) => {
      if (!userInput.trim() || !dataSourceId) return;

      // reset stopped message, input, and data model selection
      setStoppedMessage(null);
      storeDispatch(setChatInput(''));

      // add user message to the chat feed
      handleChatMessageForCurrentAndLoadedChat({
        type: ChatFeedMessageType.USER_MESSAGE,
        message: userInput,
        trigger: 'handleUserMessage',
        chatThreadId: initializedChatThreadIdRef.current,
        senderName: firstName,
      });

      // add thinking ai message immediately
      // this will fill the gap between user prompt and the first response
      // this will be replaced with the actual response from the backend
      storeDispatch(
        updateChatState({
          chatState: {
            isThinking: true,
          },
          trigger: 'handleUserMessage',
        }),
      );
      const thinkingResponse: PickedChatResponse = {
        id: 'thinking',
        classifiedThoughts: [
          {
            classification: ThoughtClassification.Step,
            updatedAt: new Date().toISOString(),
            message: 'Thinking',
          },
        ],
        completed: false,
        canceled: false,
        createdAt: new Date().toISOString(),
      };

      handleChatMessageForCurrentAndLoadedChat({
        type: ChatFeedMessageType.RESPONSE,
        message: thinkingResponse,
        trigger: 'addThinkingMessageToChatFeed',
        chatThreadId: initializedChatThreadIdRef.current,
      });

      // prepare messages to send to the backend
      const messagesToSend: ChatRequestMessage[] = [
        ...openAIChatMsgs,
        {
          role: ChatRole.USER,
          content: userInput,
          isQuickFollow: isQuickFollow,
        },
      ];
      setOpenAIChatMsgs(messagesToSend);
      await sendUserMessageToBackend(dataSourceId, messagesToSend, userInput);
    },
    [
      dataSourceId,
      storeDispatch,
      handleChatMessageForCurrentAndLoadedChat,
      firstName,
      openAIChatMsgs,
      sendUserMessageToBackend,
    ],
  );

  const regenerateResponse = useCallback(
    async (chatResponseId: string) => {
      const thinkingResponse: PickedChatResponse = {
        id: 'thinking',
        classifiedThoughts: [
          {
            classification: ThoughtClassification.Step,
            updatedAt: new Date().toISOString(),
            message: 'Thinking',
          },
        ],
        completed: false,
        canceled: false,
        createdAt: new Date().toISOString(),
      };

      handleChatMessageForCurrentAndLoadedChat({
        type: ChatFeedMessageType.RESPONSE,
        message: thinkingResponse,
        trigger: 'regenerateResponse',
        chatThreadId: initializedChatThreadIdRef.current,
      });

      const messageToSend =
        stoppedMessage ?? openAIChatMsgs[openAIChatMsgs.length - 1].content;
      if (messageToSend) {
        // add to inprogress chat threads
        storeDispatch(
          addInProgressChatThreadById({
            chatThreadId: initializedChatThreadIdRef.current,
            chatMessageId: chatResponseId,
            completed: false,
          }),
        );

        await startChatResponse({
          variables: {
            input: {
              workspaceId,
              chatResponseId,
              messages: [
                {
                  role: ChatRole.USER,
                  content: messageToSend,
                },
              ],
              dataSourceId:
                dataSourceId === AUTOMATIC_EXPLORE_VALUE ? null : dataSourceId,
              selectedCustomerFilterValues,
              temperature: chatConfig.temperature,
              model: chatConfig.model,
              processingOptions: {
                enableQuickFollow: chatConfig.enableQuickFollow,
                enableAnswerabilityCheck: chatConfig.enableAnswerabilityCheck,
                enableCommonQuestionHints: chatConfig.enableCommonQuestionHints,
                enableDataScientist: chatConfig.enableDataScientist,
                enableFieldReduction: chatConfig.enableFieldReduction,
                enableDataTransformation: chatConfig.enableDataTransformation,
                enableEmbeddings: chatConfig.enableEmbeddings,
                vizGenerationMode: chatConfig.vizGenerationMode,
              },
              embedHostContext: isEmbedded ? hostContext : undefined,
            },
          },
        })
          .then((response) => {
            const result = response.data
              ?.startChatResponse as StartChatResponseResult;
            if (result) {
              handleStartChatResponse(result, messageToSend);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    [
      hostContext,
      isEmbedded,
      stoppedMessage,
      openAIChatMsgs,
      storeDispatch,
      startChatResponse,
      workspaceId,
      handleStartChatResponse,
      handleChatMessageForCurrentAndLoadedChat,
      chatConfig,
      dataSourceId,
      selectedCustomerFilterValues,
    ],
  );

  // when user clicks the stop generate button,
  // stop polling and show the stopped message
  const stopReceivingChatResponse = useCallback(() => {
    const chatResponseId = currentChatResponseId.current;
    if (!chatResponseId) return;

    storeDispatch(
      completeInProgressChatThreadById(initializedChatThreadIdRef.current),
    );
    storeDispatch(
      removeInProgressChatThreadById(initializedChatThreadIdRef.current),
    );

    updateCurrentAndLoadedChatState(
      {
        isRunning: false,
      },
      initializedChatThreadIdRef.current,
      'stopReceivingChatResponse',
    );

    setStoppedMessage(
      openAIChatMsgs[openAIChatMsgs.length - 1]
        ? openAIChatMsgs[openAIChatMsgs.length - 1].content
        : null,
    );

    const cancelText =
      'Per your request, I have stopped responding to your messages. Please ask a new question.';

    handleChatMessageForCurrentAndLoadedChat({
      type: ChatFeedMessageType.CANCEL,
      message: cancelText,
      trigger: 'stopReceivingChatResponse',
      chatThreadId: initializedChatThreadIdRef.current,
    });

    stopChatResponse({
      variables: {
        input: {
          workspaceId,
          chatResponseId,
        },
      },
    })
      .then((res) => {
        if (res.data?.stopChatResponse.chatResponse) {
          handleChatResponse(
            res.data.stopChatResponse.chatResponse as ChatResponse,
          );
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        // clean up the subscription to response updates last
        // in case more are sent during the processing of the stop mutation
        cancelStreamingUpdates();
      });
  }, [
    handleChatMessageForCurrentAndLoadedChat,
    openAIChatMsgs,
    stopChatResponse,
    storeDispatch,
    updateCurrentAndLoadedChatState,
    workspaceId,
    handleChatResponse,
    cancelStreamingUpdates,
  ]);

  const handleStopOrRegenerate = useCallback(
    (shouldStop: boolean) => {
      if (currentChatResponseId.current) {
        if (shouldStop) {
          stopReceivingChatResponse();
        } else {
          regenerateResponse(currentChatResponseId.current);
        }
      }
    },
    [regenerateResponse, stopReceivingChatResponse],
  );

  const updateLastMsgInView = useCallback(
    (inView: boolean) => {
      storeDispatch(setLastMsgInView(inView));
    },
    [storeDispatch],
  );

  return {
    isLoading,
    initializeChat,
    handleUserMessage,
    stopReceivingChatResponse,
    regenerateResponse,
    handleStopOrRegenerate,
    resetChatMemory,
    rateChatResponse,
    updateCurrentAndLoadedChatState,
    updateLastMsgInView,
    handleChatMessageForCurrentAndLoadedChat, // export for test only
  };
};
