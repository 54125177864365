import { Stack } from '@mui/material';
import { ActionType, MultiStepFormValues } from '../types';
import SlackPreview from '../Previews/SlackPreview';
import EmailPreview from '../Previews/EmailPreview';

interface PreviewProps {
  formValues: MultiStepFormValues;
  vizTitle: string;
}

const Preview = ({ formValues, vizTitle }: PreviewProps) => {
  const isEmail = formValues.message.actionType === ActionType.Email;
  const isSlack = formValues.message.actionType === ActionType.Slack;

  return (
    <Stack id='preview-step' spacing={4}>
      {isEmail && (
        <EmailPreview
          vizTitle={vizTitle}
          recipients={formValues.message.recipients ?? []}
          emailBody={formValues.message.emailBody ?? ''}
        />
      )}
      {isSlack && formValues.message.slackMessage && (
        <SlackPreview
          message={formValues.message.slackMessage ?? ''}
          vizTitle={vizTitle}
        />
      )}
    </Stack>
  );
};

export default Preview;
